import React, { useEffect, useState } from "react";
import logo from "../../assets/images/reportLogo.png";
import { useLocation } from "react-router";
import ApiService from "../../services/APIServices";
import useAsyncImageSrc from "../../Helper/useAsyncImageSrc.jsx";
export const InterimReport = () => {
  const location = useLocation();
  const [verificationInfo, setVerificationInfo] = useState(null);
  const [experienceInfo, setExperienceInfo] = useState([]);
  const [educationInfo, setEducationInfo] = useState([]);
  const [referenceInfo, setReferenceInfo] = useState([]);
  const [addressInfo, setAddressInfo] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState([]);
  const [drugInfo, setDrugInfo] = useState([]);
  const [globalDBInfo, setGlobalDBInfo] = useState([]);
  const [sexOffenderInfo, setSexOffenderInfo] = useState([]);

  const title = process.env.REACT_APP_REFERENCE_TITLE;

  const getUserVerificationReqInfo = async (userVerificationReqId) => {
    await ApiService.get(`userVerificationRequest/${userVerificationReqId}`)
      .then((res) => {
        setVerificationInfo(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEducationInfo = (id) => {
    ApiService.get(`educationCheck/${id}`)
      .then((res) => {
        setEducationInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReferenceInfo = (id) => {
    ApiService.get(`referenceCheck/${id}`)
      .then((res) => {
        setReferenceInfo(res?.data?.docs);
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAddressInfo = (id) => {
    ApiService.get(`addressCheck/${id}`)
      .then((res) => {
        setAddressInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCertificateInfo = (id) => {
    ApiService.get(`certificatesCheck/${id}`)
      .then((res) => {
        setCertificateInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGlobalDBInfo = (id) => {
    ApiService.get(`globalDatabaseCheck/${id}`)
      .then((res) => {
        setGlobalDBInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSexOffenderInfo = (id) => {
    ApiService.get(`sexOffenderCheck/${id}`)
      .then((res) => {
        setSexOffenderInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDrugInfo = (id) => {
    ApiService.get(`drugCheck/${id}`)
      .then((res) => {
        setDrugInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {}, [verificationInfo]);

  useEffect(() => {
    getUserVerificationReqInfo(location?.state?.id);
    getEducationInfo(location?.state?.id);
  }, [location?.state?.id]);

  useEffect(() => {
    getExperienceInfo(location?.state?.id);
    getReferenceInfo(location?.state?.id);
    getAddressInfo(location?.state?.id);
    getCertificateInfo(location?.state?.id);
    getGlobalDBInfo(location?.state?.id);
    getSexOffenderInfo(location?.state?.id);
    getDrugInfo(location?.state?.id);
  }, [location?.state?.id]);

  const formatDate = (dateString) => {
    let date;
    if (dateString) {
      date = new Date(dateString);
    } else {
      date = new Date();
    }
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <img src={imgSrc} alt={alt || "image"} className={className || ""} />
    );
  };

  return (
    <>
      {verificationInfo !== null && (
        <>
          <div
            className="page-header"
            style={{
              height: "190px",
            }}
          >
            <div
              className="flex flex-col justify-center items-center mt-[30px]"
              style={{ height: "100px", padding: "10px" }}
            >
              <img
                src={logo}
                style={{ height: "70px", width: "206px", margin: "auto" }}
                alt="Logo"
              />
              <div
                style={{
                  wordBreak: "break-word",
                  textAlign: "center",
                  width: "343px",
                }}
                className="font-medium text-[16px] text-[#413C3C]"
              >
                A Brand of Verify Kart Screening Services <br />
                588 Sector A Mahalaxmi Nagar Indore M.P. Email
                Id:info@verifycheck.in Website:verifycheck.in
              </div>

              <button
                type="button"
                className="h-[190px] w-[200px]"
                onClick={() => window.print()}
                style={{ background: "pink" }}
              >
                PRINT ME!
              </button>
            </div>
          </div>
          {/* <div className="text-center page-footer border border-black">
            <span> CIN: U93090DL2023PPC660811</span>
            <br />
            <span className="font-bold">
              {" "}
              <a href="https://www.coincircletrust.com">
                https://www.coincircletrust.com/
              </a>{" "}
              / an ISO9001:2020 Certified
            </span>
          </div> */}
          <table className="mt-[66px]">
            <thead>
              <tr>
                <td>
                  {/* place holder for the fixed-position header */}
                  <div className="page-header-space"></div>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {/* *** CONTENT GOES HERE *** */}
                  <div className="page">
                    <div className="flex flex-row mt-7 p-[15px] bg-gray-300 justify-center items-center border border-black">
                      <h3>FINAL BACKGROUND REPORT</h3>
                    </div>

                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                      className="mt-2 border border-black"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            Name of the Client
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {verificationInfo?.companyInfo?.name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Name of the Candidate
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {`${verificationInfo?.userInfo.firstName} ${verificationInfo?.userInfo.lastName}`}
                          </td>
                        </tr>

                        {/* <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Date Of Birth
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {new Date(
                              verificationInfo?.userInfo?.dateOfBirth
                            ).toLocaleDateString("en-GB")}
                          </td>
                        </tr> */}

                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Case Ref. Number
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {`${title}-${verificationInfo?.case}`}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Date Of Report
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                            }}
                          >
                            {formatDate(verificationInfo?.finalReportDate)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Verification Status
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            :
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              color: "green",
                            }}
                          >
                            {verificationInfo?.status === "Approved"
                              ? "Clear"
                              : verificationInfo?.status}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6 className="text-center mt-4">Color Code</h6>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-[5%]"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "green",
                              fontWeight: "600",
                            }}
                            className=""
                          >
                            Green
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Clear - All Information provided by the Candidate is
                            accurate
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "red",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Red
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Authentication status is negative / Major
                            discrepancy
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#FFD700",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Amber
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Insufficient Information / Inaccessible for
                            verification / Unable to Verify
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#04359b",
                              fontWeight: "600",
                            }}
                            className="employee-label"
                          >
                            Blue
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            WIP
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="page">
                    <h6 className="text-center mt-[15%]">Executive Summary</h6>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-[30px] border border-black"
                    >
                      <tbody>
                        <th
                          style={{
                            border: "2px solid black",
                            padding: "8px",
                            textAlign: "center",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "600",
                          }}
                        >
                          Checks
                        </th>{" "}
                        <th
                          style={{
                            border: "2px solid black",
                            padding: "8px",
                            textAlign: "center",
                            backgroundColor: "#f2f2f2",
                            fontWeight: "600",
                          }}
                        >
                          Status
                        </th>{" "}
                        {/* {verificationInfo?.finalReportInfo?.aadhaarAvidence
                          ?.proofUrl?.length !== 0 ? (
                          <tr>
                            <td
                              style={{
                                // border: "2px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                              className="border border-black"
                            >
                              Aadhar Card Verification
                            </td>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                              }}
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.aadhaarAvidence?.status === "Clear"
                                  ? "border border-black bg-green-700"
                                  : "border border-black bg-red-500"
                              }
                            >
                              <span
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.aadhaarAvidence?.status === "Clear"
                                    ? "text-black-800	font-bold"
                                    : "text-black-800 font-bold"
                                }
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.aadhaarAvidence?.status
                                }
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )} */}
                        {/* {verificationInfo?.finalReportInfo?.panAvidence
                          ?.proofUrl?.length !== 0 ? (
                          <tr>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                              className="border border-black"
                            >
                              Pan Record Check
                            </td>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                              }}
                              className={
                                verificationInfo?.finalReportInfo?.panAvidence
                                  ?.status === "Clear"
                                  ? "border border-black bg-green-700"
                                  : "border border-black bg-red-500"
                              }
                            >
                              <span
                                className={
                                  verificationInfo?.finalReportInfo?.panAvidence
                                    ?.status === "Clear"
                                    ? "text-black-800	font-bold"
                                    : "text-black-800 font-bold"
                                }
                              >
                                {
                                  verificationInfo?.finalReportInfo?.panAvidence
                                    ?.status
                                }
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )} */}
                        {/* {verificationInfo?.finalReportInfo?.courtAvidence
                          ?.proofUrl?.length !== 0 ? (
                          <tr>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                              className="border border-black"
                            >
                              Court Record Check
                            </td>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                              }}
                              className={
                                verificationInfo?.finalReportInfo?.courtAvidence
                                  ?.status === "Clear"
                                  ? "border border-black bg-green-700"
                                  : "border border-black bg-red-500"
                              }
                            >
                              <span
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.courtAvidence?.status === "Clear"
                                    ? "text-black-800	font-bold"
                                    : "text-black-800 font-bold"
                                }
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.courtAvidence?.status
                                }
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )} */}
                        {educationInfo?.length !== 0 &&
                          educationInfo?.some(
                            (educationEvidence) =>
                              educationEvidence?.education?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {educationInfo.map((educationEvidence, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                  className="border border-black"
                                >
                                    Education Check
                                </td>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                  className={
                                    educationEvidence?.education?.status ===
                                      "Approved"
                                      ? "border border-black bg-green-700"
                                      : "border border-black bg-red-500"
                                  }
                                >
                                  <span className="text-black-800 font-bold">
                                    {educationEvidence?.education?.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {addressInfo?.length !== 0 &&
                          addressInfo?.some(
                            (addressEvidence) =>
                              addressEvidence?.address?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {addressInfo.map((addressEvidence, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                  className="border border-black"
                                >
                                    Address Check
                                </td>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                  className={
                                    addressEvidence?.address?.status ===
                                      "Approved"
                                      ? "border border-black bg-green-700"
                                      : "border border-black bg-red-500"
                                  }
                                >
                                  <span className="text-black-800 font-bold">
                                    {addressEvidence?.address?.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {/* {verificationInfo?.finalReportInfo
                          ?.currentAddressAvidence?.proofUrl?.length !== 0 ? (
                          <tr>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                              className="border border-black"
                            >
                              Current Address Record Check
                            </td>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                              }}
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.currentAddressAvidence
                                  ?.statusOfCurrentAddress === "Clear"
                                  ? "border border-black bg-green-700"
                                  : "border border-black bg-red-500"
                              }
                            >
                              <span
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence
                                    ?.statusOfCurrentAddress === "Clear"
                                    ? "text-black-800	font-bold"
                                    : "text-black-800 font-bold"
                                }
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.currentAddressAvidence
                                    ?.statusOfCurrentAddress
                                }
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )} */}
                        {experienceInfo?.length !== 0 &&
                          experienceInfo?.some(
                            (experienceEvidence) =>
                              experienceEvidence?.experience?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {experienceInfo.map(
                              (experienceEvidence, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      // border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                    className="border border-black"
                                  >
                                      Experience Check
                                  </td>
                                  <td
                                    style={{
                                      // border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                    className={
                                      experienceEvidence?.experience
                                        ?.status === "Approved"
                                        ? "border border-black bg-green-700"
                                        : "border border-black bg-red-500"
                                    }
                                  >
                                    <span className="text-black-800 font-bold">
                                      {experienceEvidence?.experience?.status}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                        {/* {verificationInfo?.finalReportInfo
                          ?.drivingLicenceAvidence?.proofUrl?.length !== 0 ? (
                          <tr>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                              className="border border-black"
                            >
                              Driving Licence Record Check
                            </td>
                            <td
                              style={{
                                // border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                              }}
                              className={
                                verificationInfo?.finalReportInfo
                                  ?.drivingLicenceAvidence?.status === "Clear"
                                  ? "border border-black bg-green-700"
                                  : "border border-black bg-red-500"
                              }
                            >
                              <span
                                className={
                                  verificationInfo?.finalReportInfo
                                    ?.drivingLicenceAvidence?.status === "Clear"
                                    ? "text-black-800	font-bold"
                                    : "text-black-800 font-bold"
                                }
                              >
                                {
                                  verificationInfo?.finalReportInfo
                                    ?.drivingLicenceAvidence?.status
                                }
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )} */}
                        {referenceInfo?.length !== 0 &&
                          referenceInfo?.some(
                            (referenceEvidence) =>
                              referenceEvidence?.reference?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {referenceInfo.map((referenceEvidence, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                  className="border border-black"
                                >
                                    Reference Check
                                </td>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                  className={
                                    referenceEvidence?.reference?.status ===
                                      "Approved"
                                      ? "border border-black bg-green-700"
                                      : "border border-black bg-red-500"
                                  }
                                >
                                  <span className="text-black-800 font-bold">
                                    {referenceEvidence?.reference?.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {sexOffenderInfo?.length !== 0 &&
                          sexOffenderInfo?.some(
                            (sexOffenderEvidence) =>
                              sexOffenderEvidence?.sexOffender?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {sexOffenderInfo.map(
                              (sexOffenderEvidence, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      // border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                    className="border border-black"
                                  >
                                      Sex Offender Check
                                  </td>
                                  <td
                                    style={{
                                      // border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                    className={
                                      sexOffenderEvidence?.sexOffender
                                        ?.status === "Approved"
                                        ? "border border-black bg-green-700"
                                        : "border border-black bg-red-500"
                                    }
                                  >
                                    <span className="text-black-800 font-bold">
                                      {
                                        sexOffenderEvidence?.sexOffender
                                          ?.status
                                      }
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                        {globalDBInfo?.length !== 0 &&
                          globalDBInfo?.some(
                            (globalDBEvidence) =>
                              globalDBEvidence?.globalDatabase?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {globalDBInfo.map((globalDBEvidence, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                  className="border border-black"
                                >
                                    Global Database Check
                                </td>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                  className={
                                    globalDBEvidence?.globalDatabase
                                      ?.status === "Approved"
                                      ? "border border-black bg-green-700"
                                      : "border border-black bg-red-500"
                                  }
                                >
                                  <span className="text-black-800 font-bold">
                                    {globalDBEvidence?.globalDatabase?.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {drugInfo?.length !== 0 &&
                          drugInfo?.some(
                            (drugEvidence) =>
                              drugEvidence?.drug?.uploadProofUrl?.length !== 0
                          ) && (
                          <>
                            {drugInfo.map((drugEvidence, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                  className="border border-black"
                                >
                                    Drug Check
                                </td>
                                <td
                                  style={{
                                    // border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                  className={
                                    drugEvidence?.drug?.status === "Approved"
                                      ? "border border-black bg-green-700"
                                      : "border border-black bg-red-500"
                                  }
                                >
                                  <span className="text-black-800 font-bold">
                                    {drugEvidence?.drug?.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                        {certificateInfo?.length !== 0 &&
                          certificateInfo?.some(
                            (certificateEvidence) =>
                              certificateEvidence?.certificates?.uploadProofUrl
                                ?.length !== 0
                          ) && (
                          <>
                            {certificateInfo.map(
                              (certificateEvidence, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      // border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                    className="border border-black"
                                  >
                                      Certificate Check
                                  </td>
                                  <td
                                    style={{
                                      // border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                    className={
                                      certificateEvidence?.certificates
                                        ?.status === "Approved"
                                        ? "border border-black bg-green-700"
                                        : "border border-black bg-red-500"
                                    }
                                  >
                                    <span className="text-black-800 font-bold">
                                      {
                                        certificateEvidence?.certificates
                                          ?.status
                                      }
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* aadhar Verification report ui */}
                  {/* {verificationInfo?.finalReportInfo?.aadhaarAvidence?.proofUrl
                    ?.length > 0 ? (
                    <div className="page">
                      <h3 className="text-center mt-[100px]">
                        Evidence Documents for Aadhar Verification
                      </h3>
                      {verificationInfo?.finalReportInfo?.aadhaarAvidence?.proofUrl?.map(
                        (item) => {
                          return (
                            <>
                              <div className="mx-auto mt-[60px]">
                                <img
                                  src={item}
                                  alt="education evidence"
                                  className="h-[500px] w-full m-auto"
                                />
                              </div>
                            </>
                          );
                        }
                      )}
                      <tr>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "600",
                            color: "green",
                          }}
                          className="employee-label"
                        >
                          Status :
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "600",
                            color: "green",
                          }}
                        >
                          <span
                            className={
                              verificationInfo?.finalReportInfo?.aadhaarAvidence
                                ?.status === "Clear"
                                ? "text-green-600	font-bold"
                                : "text-red-600 font-bold"
                            }
                          >
                            {verificationInfo?.finalReportInfo?.aadhaarAvidence
                              ?.status === "Clear"
                              ? "Clear"
                              : "text-red-600 font-bold"}
                          </span>
                        </td>
                      </tr>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* pan */}

                  {/* {verificationInfo?.finalReportInfo?.panAvidence?.proofUrl
                    ?.length > 0 ? (
                    <div className="page">
                      <h3 className="text-center mt-[100px]">
                        Evidence Documents for Pan Verification
                      </h3>
                      {verificationInfo?.finalReportInfo?.panAvidence?.proofUrl?.map(
                        (item) => {
                          return (
                            <>
                              <div className="mx-auto mt-5">
                                <img
                                  src={item}
                                  alt="education evidence"
                                  className="h-[500px] w-full m-auto"
                                />
                              </div>
                            </>
                          );
                        }
                      )}
                      <tr>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "600",
                            color: "green",
                          }}
                          className="employee-label"
                        >
                          Status :
                        </td>
                        <td
                          className="text-green-600	font-bold"
                          style={{
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={
                              verificationInfo?.finalReportInfo?.panAvidence
                                ?.status === "Clear"
                                ? "text-green-600	font-bold"
                                : "text-red-600 font-bold"
                            }
                          >
                            {verificationInfo?.finalReportInfo?.panAvidence
                              ?.status === "Clear"
                              ? "Green"
                              : verificationInfo?.finalReportInfo?.panAvidence
                                  ?.status}
                          </span>
                        </td>
                      </tr>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* Criminal Record Verification report ui */}

                  {/* court */}
                  {/* {verificationInfo?.finalReportInfo?.courtAvidence?.proofUrl
                    ?.length > 0 ? (
                    <div className="page">
                      <h3 className="text-center mt-[100px]">
                        Evidence of Court Record Verification
                      </h3>
                      {verificationInfo?.finalReportInfo?.courtAvidence?.proofUrl?.map(
                        (item) => {
                          return (
                            <>
                              <div className="mx-auto mt-5">
                                <img
                                  src={item}
                                  alt="education evidence"
                                  className="h-[500px] w-full m-auto"
                                />
                              </div>
                            </>
                          );
                        }
                      )}

                      <tr>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "600",
                            color: "green",
                          }}
                          className="employee-label"
                        >
                          Status :
                        </td>
                        <td
                          className="text-green-600	font-bold"
                          style={{
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={
                              verificationInfo?.finalReportInfo?.courtAvidence
                                ?.status === "Clear"
                                ? "text-green-600	font-bold"
                                : "text-red-600 font-bold"
                            }
                          >
                            {verificationInfo?.finalReportInfo?.courtAvidence
                              ?.status === "Clear"
                              ? "Green"
                              : verificationInfo?.finalReportInfo?.courtAvidence
                                  ?.status}
                          </span>
                        </td>
                      </tr>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* Education report ui */}
                  {educationInfo?.length > 0 &&
                  educationInfo.some(
                    (educationEvidence) =>
                      educationEvidence?.education?.uploadProofUrl?.length !== 0
                  )
                    ? educationInfo.map((educationEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Education
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  Qualification
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {educationEvidence?.education?.qualification}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Register No./Roll No.
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  educationInfo[index]?.education
                                    ?.enrollmentNumber
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  College/Insitute/School
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {educationInfo[index]?.education?.schoolName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  City
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  educationInfo[index]?.education
                                    ?.schoolAddress
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolAddress}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  University/Board
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  educationInfo[index]?.education
                                    ?.unversityName
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Period Of Study
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  educationInfo[index]?.education
                                    ?.periodOfStudy
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.periodOfStudy}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Class / Divison / %
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {educationInfo[index]?.education?.grade}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.grade}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {educationInfo[index]?.education?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    educationInfo[index]?.education.status ===
                                      "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {educationInfo[index]?.education.status}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                educationInfo[index]?.education?.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {educationInfo[index]?.education?.status ===
                                "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                        {educationInfo[index]?.education?.uploadProofUrl?.map(
                          (url, index) => (
                            <div key={index} className="mx-auto mt-[250px]">
                              <AsyncImage
                                className="h-[600px] w-full m-auto p-[90px]"
                                fileUrl={url}
                                alt="education evidence"
                                url
                              />
                              {/* <img
                                key={index}
                                src={url}
                                alt={"Proof URL for"}
                                className="h-[700px] w-full m-auto p-[90px]"
                              /> */}
                            </div>
                          )
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                educationInfo[index]?.education?.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {educationInfo[index]?.education?.status ===
                                "Approved"
                                ? "Clear"
                                : educationInfo[index]?.education?.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}

                  {/* Address report ui */}
                  {addressInfo?.length > 0 &&
                  addressInfo.some(
                    (addressEvidence) =>
                      addressEvidence?.address?.uploadProofUrl?.length !== 0
                  )
                    ? addressInfo.map((addressEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Address
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  Address Type
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.typeOfAddress}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Landmark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.landmark}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  City
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.city}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  State
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.state}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolAddress}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Pin Code
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.pincode}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Period Of Stay
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.periodOfStay}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.periodOfStudy}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {addressEvidence?.address?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    addressInfo[index]?.address.status ===
                                      "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {addressInfo[index]?.address.status}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                addressInfo[index]?.address?.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {addressInfo[index]?.address?.status ===
                                "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                        {addressInfo[index]?.address?.uploadProofUrl?.map(
                          (url, index) => (
                            <div key={index} className="mx-auto mt-[250px]">
                              <AsyncImage
                                className="h-[600px] w-full m-auto p-[90px]"
                                fileUrl={url}
                                alt="education evidence"
                                url
                              />
                              {/* <img
                                  key={index}
                                  src={url}
                                  alt={"Proof URL for"}
                                  className="h-[600px] w-full m-auto p-[90px]"
                                /> */}
                            </div>
                          )
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                addressInfo[index]?.address?.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {addressInfo[index]?.address?.status ===
                                "Approved"
                                ? "Clear"
                                : addressInfo[index]?.address?.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}

                  {/* Certificate report ui */}
                  {certificateInfo?.length > 0 &&
                  certificateInfo.some(
                    (certificateEvidence) =>
                      certificateEvidence?.certificates?.uploadProofUrl
                        ?.length !== 0
                  )
                    ? certificateInfo.map((certificateEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Certificate
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {certificateEvidence?.certificates?.name}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Organization
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  certificateEvidence?.certificates
                                    ?.organization
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Issue Date
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {certificateEvidence?.certificates?.issueDate}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Expiration Date
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  certificateEvidence?.certificates
                                    ?.expirationDate
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolAddress}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Credential URL
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  certificateEvidence?.certificates
                                    ?.credentialUrl
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {certificateEvidence?.certificates?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    certificateInfo[index]?.certificates
                                      .status === "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {
                                    certificateInfo[index]?.certificates
                                      .status
                                  }
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                certificateInfo[index]?.certificates
                                  .status === "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {certificateInfo[index]?.certificates.status ===
                                "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                        {certificateInfo[
                          index
                        ]?.certificates?.uploadProofUrl?.map((url, index) => (
                          <div key={index} className="mx-auto mt-[250px]">
                            <AsyncImage
                              className="h-[600px] w-full m-auto p-[90px]"
                              fileUrl={url}
                              alt="education evidence"
                              url
                            />
                            {/* <img
                                key={index}
                                src={url}
                                alt={"Proof URL for"}
                                className="h-[600px] w-full m-auto p-[90px]"
                              /> */}
                          </div>
                        ))}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                certificateInfo[index]?.certificates
                                  .status === "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {certificateInfo[index]?.certificates.status ===
                                "Approved"
                                ? "Clear"
                                : certificateInfo[index]?.certificates.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}

                  {/* Previous Employment report ui */}
                  {experienceInfo?.length > 0 &&
                  experienceInfo.some(
                    (experienceEvidence) =>
                      experienceEvidence?.experience?.uploadProofUrl?.length !==
                      0
                  )
                    ? experienceInfo.map((experienceEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Previous Employment
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  Type
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {experienceEvidence?.experience?.type}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Company Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {experienceEvidence?.experience?.companyName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Designation
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {experienceEvidence?.experience?.designation}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Period Of Employment(Months)
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {
                                  experienceEvidence?.experience
                                    ?.periodOfEmployement
                                }
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolAddress}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  HR Email
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {experienceEvidence?.experience?.hrEmail}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {experienceEvidence?.experience?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    experienceInfo[index]?.experience
                                      .status === "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {experienceInfo[index]?.experience.status}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                experienceInfo[index]?.experience.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {experienceInfo[index]?.experience.status ===
                                "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                        {certificateInfo[
                          index
                        ]?.certificates?.uploadProofUrl?.map((url, index) => (
                          <div key={index} className="mx-auto mt-[250px]">
                            <AsyncImage
                              className="h-[600px] w-full m-auto p-[90px]"
                              fileUrl={url}
                              alt="education evidence"
                              url
                            />
                            {/* <img
                                key={index}
                                src={url}
                                alt={"Proof URL for"}
                                className="h-[600px] w-full m-auto p-[90px]"
                              /> */}
                          </div>
                        ))}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                experienceInfo[index]?.experience.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {experienceInfo[index]?.experience.status ===
                                "Approved"
                                ? "Clear"
                                : experienceInfo[index]?.experience.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}

                  {/* Drug report ui */}
                  {drugInfo?.length > 0 &&
                  drugInfo.some(
                    (drugEvidence) =>
                      drugEvidence?.drug?.uploadProofUrl?.length !== 0
                  )
                    ? drugInfo.map((drugEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Drug
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  Type Of Report
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.name}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Clinic Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.clinicName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Doctor Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.doctorName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Clinic Address
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.clinicAddress}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolAddress}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Test Date
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.testDate}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Report Date
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.reportDate}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {drugEvidence?.drug?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    drugInfo[index]?.drug.status ===
                                      "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {drugInfo[index]?.drug.status}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                drugInfo[index]?.drug.status === "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {drugInfo[index]?.drug.status === "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                        {drugInfo[index]?.drug?.uploadProofUrl?.map(
                          (url, index) => (
                            <div key={index} className="mx-auto mt-[250px]">
                              <AsyncImage
                                className="h-[600px] w-full m-auto p-[90px]"
                                fileUrl={url}
                                alt="education evidence"
                                url
                              />
                              {/* <img
                                  key={index}
                                  src={url}
                                  alt={"Proof URL for"}
                                  className="h-[600px] w-full m-auto p-[90px]"
                                /> */}
                            </div>
                          )
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                drugInfo[index]?.drug.status === "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {drugInfo[index]?.drug.status === "Approved"
                                ? "Clear"
                                : drugInfo[index]?.drug.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}
                  {/* Reference report ui */}
                  {referenceInfo?.length > 0 &&
                  referenceInfo.some(
                    (referenceEvidence) =>
                      referenceEvidence?.reference?.status !== "Pending"
                  )
                    ? referenceInfo.map((referenceEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Reference
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  Reference Type
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.type}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Full Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.fullName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Contact No.
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.contactNumber}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Email
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.email}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolAddress}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  City
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.city}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  State
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.state}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.unversityName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.reference?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    referenceInfo[index]?.reference.status ===
                                      "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {referenceInfo[index]?.reference.status}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                referenceInfo[index]?.reference.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {referenceInfo[index]?.reference.status ===
                                "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}

                  {globalDBInfo?.length > 0 &&
                  globalDBInfo.some(
                    (drugEvidence) =>
                      drugEvidence?.globalDatabase?.uploadProofUrl?.length !== 0
                  )
                    ? globalDBInfo.map((drugEvidence, index) => (
                      <div key={index} className="page">
                        {/* <h3 className="text-center mt-[100px]">
                            Evidence Document for Global DataBase
                          </h3> */}

                        {globalDBInfo[
                          index
                        ]?.globalDatabase?.uploadProofUrl?.map(
                          (url, index) => (
                            <div key={index} className="mx-auto mt-[100px]">
                              <h3 className="text-center">
                                  Evidence Document for Global DataBase
                              </h3>
                              <AsyncImage
                                className="h-[600px] w-full m-auto p-[90px]"
                                fileUrl={url}
                                alt="education evidence"
                                url
                              />
                              {/* <img
                                  key={index}
                                  src={url}
                                  alt={"Proof URL for"}
                                  className="h-[600px] w-full m-auto p-[90px]"
                                /> */}
                            </div>
                          )
                        )}
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                globalDBInfo[index]?.globalDatabase.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {globalDBInfo[index]?.globalDatabase.status ===
                                "Approved"
                                ? "Clear"
                                : globalDBInfo[index]?.globalDatabase.status}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}

                  {sexOffenderInfo?.length > 0 &&
                  sexOffenderInfo.some(
                    (referenceEvidence) =>
                      referenceEvidence?.sexOffender?.status !== "Pending"
                  )
                    ? sexOffenderInfo.map((referenceEvidence, index) => (
                      <div key={index} className="page">
                        <h3 className="text-center mt-[100px]">
                            Evidence Document for Sex Offender
                        </h3>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            width: "100%",
                          }}
                          className="mt-5"
                        >
                          <tbody>
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Details
                            </th>{" "}
                            <th
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "center",
                                backgroundColor: "#f2f2f2",
                                fontWeight: "600",
                              }}
                            >
                                Information Provided
                            </th>{" "}
                            {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                    backgroundColor: "#f2f2f2",
                                    fontWeight: "600",
                                  }}
                                >
                                  Information Verified
                                </th>{" "} */}
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className=""
                              >
                                  First Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.sexOffender?.firstName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.qualification}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Last Name
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.sexOffender?.lastName}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.enrollmentNumber}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Country
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.sexOffender?.country}
                              </td>
                              {/* <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {educationEvidence?.schoolName}
                                  </td> */}
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Remark
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {referenceEvidence?.sexOffender?.remark}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                  backgroundColor: "#f2f2f2",
                                  fontWeight: "600",
                                }}
                                className="employee-label"
                              >
                                  Status
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className={
                                    sexOffenderInfo[index]?.sexOffender
                                      .status === "Approved"
                                      ? "text-green-600	font-bold"
                                      : "text-red-600 font-bold"
                                  }
                                >
                                  {sexOffenderInfo[index]?.sexOffender.status}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <tr>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              fontWeight: "600",
                              color: "green",
                            }}
                            className="employee-label"
                          >
                              Status :
                          </td>
                          <td
                            className="text-green-600	font-bold"
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={
                                sexOffenderInfo[index]?.sexOffender.status ===
                                  "Approved"
                                  ? "text-green-600	font-bold"
                                  : "text-red-600 font-bold"
                              }
                            >
                              {sexOffenderInfo[index]?.sexOffender.status ===
                                "Approved"
                                ? "Clear"
                                : "Reject"}
                            </span>
                          </td>
                        </tr>
                      </div>
                    ))
                    : ""}
                  {/* ------------------------------------------------------------------------------------------ */}

                  <div className="mt-[100px] text-center">
                    <h1 className="flex flex-col ">Confidential</h1>
                  </div>

                  <div className="page">
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-[100px]"
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Color Code{" "}
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Clear
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              color: "green",
                              fontWeight: "600",
                            }}
                          >
                            Green
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Detais provided
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                      className="mt-5"
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Color Code{" "}
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              backgroundColor: "#f2f2f2",
                              fontWeight: "600",
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Clear
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                              color: "green",
                              fontWeight: "600",
                            }}
                          >
                            Green
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Detais provided
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  {/* place holder for the fixed-position footer */}
                  <div className="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>
        </>
      )}
    </>
  );
};
