import {
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import userInfoReducer from "./userInfo";


const combinedReducer = combineReducers({
  userInfo: userInfoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "counter/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
