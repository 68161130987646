import React from "react";
import { indusIndBankChecks } from "../../utils/constants";
import { Dropdown } from "primereact/dropdown";

const WeactFilterTabs = ({
  activeTab,
  filterQuery,
  setFilterQuery,
  handleTabClick,
}) => {
  return (
    <>
      {process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true" ? (
        <div className="flex lg:flex-row flex-col xl:pt-0 lg:pt-[10%] md:pt-[10%] pt-[15%] lg:overflow-x-auto overflow-style-none md:rounded-lg">
          {["Discrete Call", "VOLO Healthcare", "KEI Process", "INDUS"].map(
            (tabName) => (
              <>
                {tabName === "Discrete Call" ||
                tabName === "VOLO Healthcare" ||
                tabName === "KEI Process" ? (
                    <div
                      role="presentation"
                      key={tabName}
                      onClick={() => handleTabClick(tabName)}
                      className={`${
                        activeTab === tabName
                          ? "bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow-md text-white lg:ml-2 text-sm font-medium font-['Poppins'] lg:w-[20%] w-full"
                          : "bg-white rounded-[10px] shadow-md text-slate-400 lg:ml-2 text-sm font-medium font-['Poppins'] lg:w-[20%] w-full"
                      } p-3 mb-2 cursor-pointer flex flex-row gap-[25px]`}
                    >
                      <div className="h-[20px] lg:text-nowrap">
                        {(tabName, activeTab === tabName)}
                      </div>
                      <div className="h-[20px] text-[14px] lg:text-nowrap">
                        {tabName}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                {tabName === "INDUS" ? (
                  <Dropdown
                    value={filterQuery}
                    onChange={(event) => {
                      setFilterQuery(event.target.value);
                    }}
                    options={indusIndBankChecks}
                    placeholder="IndusInd Bank"
                    optionLabel="name"
                    optionValue="value"
                    className="h-[50px] lg:ml-3  !border-none bg-white rounded-[10px] shadow-md text-slate-400 lg:ml-2 text-sm font-medium font-['Poppins'] lg:w-[20%] w-full"
                  />
                ) : (
                  ""
                )}
              </>
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WeactFilterTabs;
