import React from "react";

export const envChecks = {
  Education: process.env.REACT_APP_IS_EDUCATION_CHECKLIST_ENABLED === "true",
  Identity: process.env.REACT_APP_IS_IDENTITY_CHECKLIST_ENABLED === "true",
  Certificate:
    process.env.REACT_APP_IS_CERTIFICATE_CHECKLIST_ENABLED === "true",
  Drug: process.env.REACT_APP_IS_DRUG_CHECKLIST_ENABLED === "true",
  Credit: process.env.REACT_APP_IS_CREDIT_CHECKLIST_ENABLED === "true",
  Court: process.env.REACT_APP_IS_COURT_CHECKLIST_ENABLED === "true",
  Police: process.env.REACT_APP_IS_POLICE_CHECKLIST_ENABLED === "true",
  Experience: process.env.REACT_APP_IS_EXPERIENCE_CHECKLIST_ENABLED === "true",
  Address: process.env.REACT_APP_IS_ADDRESS_CHECKLIST_ENABLED === "true",
  VideoKyc: process.env.REACT_APP_IS_VIDEOKYC_CHECKLIST_ENABLED === "true",
  GlobalDatabase:
    process.env.REACT_APP_IS_GLOBALDATABASE_CHECKLIST_ENABLED === "true",
  SexOffender:
    process.env.REACT_APP_IS_SEXOFFENDER_CHECKLIST_ENABLED === "true",
  Reference: process.env.REACT_APP_IS_REFERENCE_CHECKLIST_ENABLED === "true",
  EDD: process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  DISCRETECALLS: process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  KEIPROCESS: process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  VOLOHEALTHCARE:
    process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  REEDD_SURPRISE:
    process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  REEDD_APPOINTMENT:
    process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
};

export const indusIndBankChecks = [
  { name: "EDD", value: "isEDD" },
  { name: "REEDD (Appointment)", value: "isREEDDAPPOINTMENT" },
  { name: "REEDD (Surprise)", value: "isREEDDSURPRISE" },
];

export const VerificationStatusParameters = [
  { name: "All", value: "" },
  { name: "Pending", value: "Pending" },
  { name: "Completed", value: "Approved" },
];

export const paymentStatusFilter = [
  { name: "All", value: "" },
  { name: "Pending", value: "issued" },
  { name: "Paid", value: "paid" },
];

export const cooordinatorList = [
  { name: "Jyoti", value: "Jyoti" },
  { name: "Chetna", value: "Chetna" },
  { name: "Vijay", value: "Vijay" },
];

export const priorityFilters = [
  { name: " Select Priority", value: "" },
  { name: " Critical", value: "Critical" },
  { name: " High", value: "High" },
  { name: " Low", value: "Low" },
  { name: " Lowest", value: "Lowest" },
];

export const dateFilterStatus = [
  { name: "Request Date", value: "requestedDate" },
  { name: "Submission Date", value: "finalReportDate" },
];

export const getSeverity = (status) => {
  switch (status) {
  case "Rejected":
    return (
      <>
        <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
          <div className="text-red-500 text-xs font-medium m-auto">
              Rejected
          </div>
        </div>
      </>
    );

  case "Approved":
    return (
      <>
        <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-blue-500">
          <div className=" m-auto   text-emerald-500 text-xs font-medium m-auto">
              Completed
          </div>
        </div>
      </>
    );
  case "Completed":
    return (
      <>
        <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-blue-500">
          <div className=" m-auto   text-emerald-500 text-xs font-medium m-auto">
              Completed
          </div>
        </div>
      </>
    );

  case "InProgress":
    return (
      <>
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-blue-500 bg-opacity-10 rounded-[5px] border border-emerald-500">
            <div className=" m-auto text-blue-500 text-xs font-medium m-auto">
                InProgress
            </div>
          </div>
        </>
      </>
    );

  case "Pending":
    return (
      <>
        <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-amber-300 bg-opacity-10 rounded-[5px] border border-amber-300">
          <div className="text-amber-300 text-xs font-medium m-auto">
              Pending
          </div>
        </div>
      </>
    );

  case "BcaApproval":
    return (
      <>
        <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-emerald-500">
          <div className=" m-auto  text-emerald-500 text-xs font-medium m-auto">
              BcaApproval
          </div>
        </div>
      </>
    );

  case "BcaRejection":
    return (
      <>
        <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
          <div className=" m-auto  text-red-600 text-xs font-medium m-auto">
              BcaRejection
          </div>
        </div>
      </>
    );
  }
};

export const casesCheckList = [
  { name: "Education" },
  { name: "Identity" },
  { name: "Certificate" },
  { name: "Drug" },
  { name: "Credit" },
  { name: "Court" },
  { name: "Police" },
  { name: "Experience" },
  { name: "Address" },
  { name: "Reference" },
  { name: "VideoKyc" },
  { name: "GlobalDatabase" },
  { name: "SexOffender" },
  { name: "EDD" },
  { name: "DISCRETECALLS" },
  { name: "KEIPROCESS" },
  { name: "VOLOHEALTHCARE" },
  { name: "REEDD_SURPRISE" },
  { name: "REEDD_APPOINTMENT" },
];

export const educationCategories = [
  { name: "Highest Level of Education" },
  { name: "Phd" },
  { name: "Post Graduate" },
  { name: "Under Graduate" },
  { name: "Graduation" },
  { name: "12th Grade" },
  { name: "10th Grade" },
];

export const taskStatusFilter = [
  { name: "InProgress", value: "InProgress" },
  { name: "Completed", value: "Completed" },
];
