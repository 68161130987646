// inputValidation.js
export const ValidateInput = (value, type) => {
  let regex;

  switch (type) {
  case "alphanumeric":
    regex = /^[a-zA-Z0-9\s-]*$/;
    break;
  case "numeric":
    regex = /^[0-9\s-]*$/;
    break;
  case "text":
    regex = /^[a-zA-Z\s-]*$/;
    break;
  case "alphanumericWithSymbols":
    regex = /^[a-zA-Z0-9\s@._-]*$/;
    break;
  default:
    return true;
  }

  return regex.test(value);
};
