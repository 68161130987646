import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import NoData from "../../Helper/NoDataFound.jsx";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { TabView, TabPanel } from "primereact/tabview";

import EducationCheck from "./Checklist/EducationCheck.jsx";
import AddressCheck from "./Checklist/AddressCheck.jsx";
import CertificateCheck from "./Checklist/CertificateCheck.jsx";
import IdentityCheck from "./Checklist/IdentityCheck.jsx";
import CourtCheck from "./Checklist/CourtCheck.jsx";
import CreditCheck from "./Checklist/CreditCheck.jsx";
import PoliceCheck from "./Checklist/PoliceCheck.jsx";
import DrugCheck from "./Checklist/DrugCheck.jsx";
import ReferenceCheck from "./Checklist/ReferenceCheck.jsx";
import ExperienceCheck from "./Checklist/ExperienceCheck.jsx";
import VideoKycCheck from "./Checklist/VideoKycCheck.jsx";
import GlobalDatbaseCheck from "./Checklist/GlobalDatbaseCheck.jsx";
import SexOffenderCheck from "./Checklist/SexOffenderCheck.jsx";

export const UserLink = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  let webFormId;
  if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
    const { webFormId: paramWebFormId } = useParams();
    webFormId = paramWebFormId;
  } else {
    webFormId = user?.caseId;
  }


  const [ActiveTabInfo, setActiveTabInfo] = useState("Upload Documents");

  const [userInfo, setUserInfo] = useState("");

  const handleIsTabClick = (tabNameInfo) => {
    setActiveTabInfo(tabNameInfo);
  };

  const updateCaseIsDetailFilled = async () => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "userVerificationRequest/link";
    } else {
      url = "userVerificationRequest";
    }
    let body = {
      isDetailFilled: true,
      linkStatus: "DetailsFilled",
    };
    ApiService.patch(`${url}/${userInfo?._id}`, body)
      .then(() => {
        toast.success("Case Detail Filled Successfully");
        getUserVerificationReqInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   const findUserAccess = async () => {

  //     ApiService.get(`userVerificationRequest/userLink/${webFormId}`)
  //       .then((res) => {
  //         localStorage.setItem("token", res?.data?.token);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  // useEffect(() => {
  //     if (webFormId !== null) {
  //       findUserAccess();
  //     }
  //   }, [webFormId !== null]);

  const getUserVerificationReqInfo = async () => {
    ApiService.get(`userVerificationRequest/link/${webFormId}`)
      .then((res) => {
        setUserInfo(res?.data[0]);
        // setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        // setSpinner(false);
      });
  };

  useEffect(() => {
    if (webFormId !== null) {
      getUserVerificationReqInfo();
    }
  }, [webFormId !== null]);

  const renderContent = (activeTab) => {
    switch (activeTab) {
    case "Experience":
      return (
        <>
          <ExperienceCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Certificate":
      return (
        <>
          <CertificateCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Drug":
      return (
        <>
          <DrugCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Police":
      return (
        <>
          <PoliceCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Court":
      return (
        <>
          <CourtCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Credit":
      return (
        <>
          <CreditCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Reference":
      return (
        <>
          <ReferenceCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );

    case "Video KYC":
      return (
        <>
          <VideoKycCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Global Database":
      return (
        <>
          <GlobalDatbaseCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Sex Offender":
      return (
        <>
          <SexOffenderCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Education":
      return (
        <>
          <EducationCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    case "Address":
      return (
        <>
          <AddressCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );

    case "Identity":
      return (
        <>
          <IdentityCheck
            userVerificationRequestInfo={userInfo}
            info={getUserVerificationReqInfo}
            flag="false"
          />
        </>
      );
    default:
      return (
        <div>
          {" "}
          <NoData info="Ooops, no content available!!" />
        </div>
      );
    }
  };

  const renderTabsInfo = (ActiveTabInfo) => {
    switch (ActiveTabInfo) {
    // case "General Information":
    //   return (
    //     <>
    //       <GeneralInfo userInfo={userInfo} />
    //     </>
    //   );
    case "Upload Documents":
      return (
        <>
          <div
            className="flex flex-col items-center justify-start sm:px-5 rounded-[10px] w-full"
            style={{ padding: "0px" }}
          >
            <div className="mb-1 w-full">
              <>
                <div className="bg-stone-50 shadow bg-opacity-95 rounded-[10px] p-[18px] border border-stone-300 border-opacity-80 gap-[40px] mb-[100px]">
                  <div className="w-full h-auto">
                    <TabView scrollable>
                      {[
                        {
                          name: "Education",
                          condition: userInfo?.checks?.includes("Education"),
                        },
                        {
                          name: "Experience",
                          condition: userInfo?.checks?.includes("Experience"),
                        },
                        {
                          name: "Certificate",
                          condition:
                              userInfo?.checks?.includes("Certificate"),
                        },
                        {
                          name: "Drug",
                          condition: userInfo?.checks?.includes("Drug"),
                        },
                        {
                          name: "Address",
                          condition: userInfo?.checks?.includes("Address"),
                        },
                        {
                          name: "Identity",
                          condition: userInfo?.checks?.includes("Identity"),
                        },
                        {
                          name: "Police",
                          condition: userInfo?.checks?.includes("police"),
                        },
                        {
                          name: "Court",
                          condition: userInfo?.checks?.includes("Court"),
                        },
                        {
                          name: "Credit",
                          condition: userInfo?.checks?.includes("Credit"),
                        },
                        {
                          name: "Reference",
                          condition: userInfo?.checks?.includes("Reference"),
                        },
                        {
                          name: "Video KYC",
                          condition: userInfo?.checks?.includes("VideoKyc"),
                        },
                        {
                          name: "Global Database",
                          condition:
                              userInfo?.checks?.includes("GlobalDatabase"),
                        },
                        {
                          name: "Sex Offender",
                          condition:
                              userInfo?.checks?.includes("SexOffender"),
                        },
                      ]
                        .filter((tab) => tab.condition)
                        .map((tab) => (
                          <TabPanel
                            key={tab.name}
                            header={tab.name}
                            // onClick={() => handleTabClick(tab.name)}
                          >
                            {renderContent(tab.name)}
                          </TabPanel>
                        ))}
                    </TabView>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="container-xl px-auto mt-3">
        <div className="flex lg:flex-row px-[12px] flex-col xl:pt-0 lg:pt-[10%] md:pt-[10%] pt-[15%] lg:overflow-x-auto overflow-style-none md:rounded-lg gap-[20px]">
          {["Upload Documents"].map((tabName) => (
            <div
              key={Math.random().toString(36).substr(2, 9)}
              role="presentation"
              onClick={() => handleIsTabClick(tabName)}
              className={`${
                ActiveTabInfo === tabName
                  ? "bg-gradient-to-r from-sky-800 to-teal-400 rounded-[7px] shadow-md text-white text-[18px] font-medium font-['Poppins'] w-auto"
                  : "bg-[#B8B8B8] rounded-[7px] shadow-md text-white text-[18px] font-medium font-['Poppins'] w-auto"
              } py-2 px-4 h-[36px] cursor-pointer flex flex-row`}
            >
              <div className="h-[20px] lg:text-nowrap">
                {(tabName, ActiveTabInfo === tabName)}
              </div>
              <div className="h-[20px] text-[14px] lg:text-nowrap">
                {tabName}
              </div>
            </div>
          ))}
        </div>
        {ActiveTabInfo === "Upload Documents" ? (
          <>
            <div className="text-end mb-[10px] max-sm:mt-[15px] px-[12px]">
              {userInfo?.isDetailFilled !== true ? (
                <>
                  <Button
                    label="Submit Details"
                    type="button"
                    onClick={() => {
                      updateCaseIsDetailFilled();
                    }}
                    className="w-[170px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                </>
              ) : (
                <>
                  <div className="text-green-500 !font-bold text-[20px] lg:text-nowrap">
                    Details Submitted
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="h-auto w-[1px] bg-gray-300"></div>
        <div className="h-auto p-[12px] mt-[5px] ">
          {renderTabsInfo(ActiveTabInfo)}
        </div>
      </div>
    </>
  );
};
