export const customItemTemplate = (file, props) => {
  const formatSize = (size) => {
    if (size === 0) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <div>
      <div className="flex">
        <span className="text-green-500">document- </span>
        <span className="text-green-500">{formatSize(file.size)}</span>
        <button
          type="button"
          className="text-red-500 ml-2"
          onClick={() => props.onRemove(file)}
        >
          <i className="pi pi-times"></i>
        </button>
      </div>
    </div>
  );
};
