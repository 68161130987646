import { useState, useEffect } from "react";
import ApiService from "../services/APIServices";

const useAsyncImageSrc = (fileUrl) => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      if (fileUrl) {
        const fileName = fileUrl.split("/").pop();
        const response = await ApiService.get(`download/${fileName}`);
        setSrc(response?.data);
      }
    };

    fetchImage();
  }, [fileUrl]);

  return src;
};

export default useAsyncImageSrc;
