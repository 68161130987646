import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import { InputTextarea } from "primereact/inputtextarea";
import Inefficiency from "../../components/UserProfileView/Checklist/Inefficiency.jsx";
import { educationCategories, getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";

const EducationDetail = ({
  userVerificationDetail,
  taskDetail,
  educationCheckInfo,
  geteducationCheckInfo,
  flag,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  const createActivity = async (action, caseId, checkId) => {
    let body = {
      userVerificationRequestId: caseId,
      checkId: checkId,
      FEName: `${localStorageData?.firstName} ${localStorageData?.lastName}`,
      checkName: "Education",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  useEffect(() => {}, [fileUpload]);
  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "education",
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {
  //       // toast.success("Task Item Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        newData[index] = {
          ...newData[index],
          education: {
            ...newData[index].education,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(educationCheckInfo);
  }, [educationCheckInfo]);

  const updateEducationDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "educationCheck/link";
    } else {
      url = "educationCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      education: {
        ...data[index]?.education,
        reportUrl: [
          ...new Set([...data[index]?.education?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.education?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.education?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
    };

    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   educationCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Education details updated successfully");
      SetFileUplaod([]);
      SetUploadProof([]);
      if (flag === "false") {
        geteducationCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, e, id) => {
    e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.patch(`educationCheck/education/status/${id}`, body);
      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // getDiscreteCallInfo(userVerificationDetail);
      geteducationCheckInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Education Task Submitted!",
                userVerificationDetail,
                educationCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex justify-between">
                    {item?.education?.status === "Pending" ? (
                      <div className="flex flex-row gap-1">
                        <div
                          role="presentation"
                          onClick={(e) => {
                            createActivity(
                              "Pending -> Approved",
                              item?.userVerificationRequestId,
                              item?._id
                            );
                            updateCheckStatus(
                              "Approved",
                              e,
                              item?._id,
                              dataIndex
                            );
                          }}
                        >
                          <Button
                            label="Approve"
                            severity="success"
                            icon="pi text-white pi-check-circle"
                            size="small"
                          />
                        </div>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            createActivity(
                              "Pending -> Rejected",
                              item?.userVerificationRequestId,
                              item?._id
                            );
                            updateCheckStatus(
                              "Rejected",
                              e,
                              item?._id,
                              dataIndex
                            );
                          }}
                        >
                          <Button
                            label="Reject"
                            icon="pi text-white pi-times-circle"
                            severity="danger"
                            size="small"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>{getSeverity(item?.education?.status)}</div>
                      </>
                    )}
                  </div>
                </>
              )}
              <form>
                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      University Name
                    </label>
                    <InputText
                      className="form-control"
                      type="text"
                      name="unversityName"
                      onChange={(e) => onInputChange(e, dataIndex, "text")}
                      value={item?.education?.unversityName || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Year Of Passing
                    </label>
                    <InputText
                      className="form-control"
                      type="number"
                      name="yearOfPassing"
                      onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                      value={item?.education?.yearOfPassing || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="entityName">
                      Qualification
                    </label>
                    <Dropdown
                      value={item?.education?.qualification || ""}
                      onChange={(e) => onInputChange(e, dataIndex)}
                      options={educationCategories}
                      optionLabel="name"
                      optionValue="name"
                      placeholder="Select Qualification"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                      inputId="qualification"
                      name="qualification"
                      disabled={flag === "true"}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        School Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="schoolAddress"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.education?.schoolAddress || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        University Address
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="unversityAddress"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.education?.unversityAddress || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        School Name
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="schoolName"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.education?.schoolName || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}
                </div>

                <div className="row my-2">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="clientType">
                      Enrollment no./Roll.no
                    </label>
                    <InputText
                      className="form-control"
                      type="number"
                      name="enrollmentNumber"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.education?.enrollmentNumber || ""}
                      disabled={flag === "true"}
                    />
                  </div>

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Roll no.
                      </label>
                      <InputText
                        className="form-control"
                        type="number"
                        name="rollNumber"
                        onChange={(e) => onInputChange(e, dataIndex, "numeric")}
                        value={item?.education?.rollNumber || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Final result/grade
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="grade"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.education?.grade || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}
                </div>

                <div className="row my-2">
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="clientType">
                        Mode Of Education
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="modeOfEducation"
                        onChange={(e) => onInputChange(e, dataIndex, "text")}
                        value={item?.education?.modeOfEducation || ""}
                        disabled={flag === "true"}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-12 col-sm-12  col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <label className="small mb-1" htmlFor="reportNumber">
                        Period Of Study
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="periodOfStudy"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.education?.periodOfStudy || ""}
                      />
                    </div>
                  )}

                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="clientType">
                        If there is any gap/break in education mention here
                        along with reasons:
                      </label>
                      <InputTextarea
                        className="form-control"
                        type="text"
                        name="comment"
                        onChange={(e) =>
                          onInputChange(e, dataIndex, "alphanumeric")
                        }
                        value={item?.education?.comment || ""}
                        disabled={flag === "true"}
                        rows={5}
                        cols={30}
                      />
                    </div>
                  )}

                  <div className="col-md-12 border-bottom">
                    <label className="small mb-1" htmlFor="clientType">
                      File Upload{" "}
                      <span className="opacity-70">
                        (Please Choose JPEG,PNG,PDF less than 5MB)
                      </span>
                    </label>
                    {item?.education?.reportUrl?.length === 0 ||
                    editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          multiple
                          auto
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={5000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={flag === "true"}
                        />
                      ) : (
                        ""
                      )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.education?.reportUrl || []).map(
                        (fileUrl, reportIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          const isPdf = fileExtension === "pdf";
                          return (
                            <li
                              key={reportIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    reportIndex,
                                    "img"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />

                              {fileMap[dataIndex]?.[reportIndex] && (
                                <div className="text-center mt-2">
                                  {isImage && (
                                    <a
                                      href={fileMap[dataIndex][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={`file_${dataIndex}_${reportIndex}`}
                                    >
                                      View Image
                                    </a>
                                  )}
                                  {isPdf && (
                                    <a
                                      href={fileMap[dataIndex][reportIndex]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-center"
                                    >
                                      View PDF
                                    </a>
                                  )}
                                </div>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  {user?.role !== "user" && (
                    <>
                      <div className="col-md-8 mt-2">
                        <label className="small mb-1" htmlFor="remark">
                          Remark
                        </label>
                        <textarea
                          className="form-control h-[110px]"
                          name="remark"
                          onChange={(e) => {
                            // setRemarkForActivity(e.target.value);
                            onInputChange(e, dataIndex, "alphanumeric");
                          }}
                          value={item?.education?.remark || ""}
                          disabled={flag === "false"}
                        />
                      </div>
                      <div className="col-md-12 my-2">
                        <label className="small mb-1" htmlFor="clientType">
                          Upload Proof{" "}
                          <span className="opacity-70">
                            (Please Choose PDF less than 10MB)
                          </span>
                        </label>
                        {item?.education?.uploadProofUrl?.length === 0 ? (
                          <FileUpload
                            name="fileUpload[]"
                            auto
                            multiple
                            accept="image/*"
                            customUpload={true}
                            maxFileSize={10000000}
                            uploadHandler={(e) =>
                              handleFileUpload(e, "uploadProof")
                            }
                            itemTemplate={customItemTemplate}
                            disabled={flag === "false"}
                          />
                        ) : (
                          ""
                        )}
                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.education?.uploadProofUrl || []).map(
                            (fileUrl, proofIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              return (
                                <li
                                  key={proofIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        proofIndex,
                                        "proof"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />
                                  <div className="text-center mt-2">
                                    {isImage && proofImage[dataIndex]?.[proofIndex] && (
                                      <a
                                        href={proofImage[dataIndex][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${dataIndex}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>{" "}
                    </>
                  )}
                </div>
                <div></div>
                <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                  {flag === "false" && item?.education?.status === "Pending" ? (
                    item?.education?.reportUrl?.length > 0 ? (
                      <Button
                        label="Edit"
                        type="button"
                        onClick={(e) => {
                          handleEdit(e);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {item?.education?.status === "Pending" ? (
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateEducationDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.education?.status)}</div>
                    </div>
                  ) : (
                    <div>{getSeverity(item?.education?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default EducationDetail;
