import React from "react";
function NoData({ info }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px 0px",
      }}
    >
      <svg
        width="175"
        height="150"
        viewBox="0 0 175 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Cloud">
          <path
            id="Vector"
            d="M43.8664 53.331L43.4598 52.8341C45.129 51.4731 46.8979 50.2392 48.7516 49.1427L49.0808 49.7042C47.2545 50.7812 45.5115 51.9936 43.8664 53.331Z"
            fill="url(#paint0_linear_43_9171)"
          />
          <path
            id="Vector_2"
            d="M30.6112 71.7748L30.011 71.549C32.2273 65.6521 35.6747 60.2951 40.1235 55.8349L40.5817 56.2931C36.1979 60.6882 32.7991 65.9656 30.6112 71.7748Z"
            fill="url(#paint1_linear_43_9171)"
          />
          <path
            id="Vector_3"
            d="M158.64 100.215C158.591 94.6129 157.174 89.1075 154.513 84.1778C151.851 79.2481 148.026 75.0434 143.368 71.9295C138.711 68.8156 133.364 66.8868 127.791 66.311C122.219 65.7351 116.59 66.5297 111.394 68.6255C107.616 60.883 101.524 54.5067 93.9613 50.3791C86.3992 46.2515 77.7409 44.5769 69.185 45.587C60.6291 46.5971 52.5988 50.242 46.2058 56.0172C39.8127 61.7923 35.3731 69.4121 33.5014 77.8216C24.226 78.4066 15.5429 82.5806 9.29255 89.4589C3.04225 96.3372 -0.283928 105.379 0.0190183 114.668C0.321965 123.957 4.23023 132.763 10.9154 139.219C17.6006 145.676 26.5372 149.275 35.8311 149.254H149.618C155.55 149.262 161.297 147.189 165.858 143.397C170.419 139.604 173.506 134.332 174.581 128.498C175.655 122.664 174.65 116.638 171.739 111.469C168.829 106.3 164.198 102.315 158.652 100.208L158.64 100.215Z"
            fill="url(#paint2_linear_43_9171)"
          />
          <path
            id="Vector_4"
            d="M130.045 88.6634H38.9878C38.2466 88.66 37.5369 88.3636 37.0134 87.8389C36.4899 87.3142 36.1952 86.6038 36.1935 85.8626C36.1952 85.122 36.4901 84.4123 37.0138 83.8886C37.5375 83.3649 38.2472 83.07 38.9878 83.0683H130.045C130.786 83.07 131.496 83.3649 132.019 83.8886C132.543 84.4123 132.838 85.122 132.84 85.8626C132.838 86.6038 132.543 87.3142 132.02 87.8389C131.496 88.3636 130.787 88.66 130.045 88.6634Z"
            fill="#00366C"
          />
          <path
            id="Vector_5"
            d="M116.312 126.48H52.7205C50.2285 126.48 47.8385 125.49 46.0764 123.728C44.3143 121.966 43.3243 119.576 43.3243 117.084C43.3243 114.592 44.3143 112.202 46.0764 110.44C47.8385 108.678 50.2285 107.688 52.7205 107.688H116.312C118.804 107.688 121.194 108.678 122.956 110.44C124.719 112.202 125.709 114.592 125.709 117.084C125.709 119.576 124.719 121.966 122.956 123.728C121.194 125.49 118.804 126.48 116.312 126.48ZM52.7205 108.333C50.3996 108.333 48.1738 109.255 46.5327 110.896C44.8916 112.538 43.9697 114.763 43.9697 117.084C43.9697 119.405 44.8916 121.631 46.5327 123.272C48.1738 124.913 50.3996 125.835 52.7205 125.835H116.312C118.633 125.835 120.859 124.913 122.5 123.272C124.141 121.631 125.063 119.405 125.063 117.084C125.063 114.763 124.141 112.538 122.5 110.896C120.859 109.255 118.633 108.333 116.312 108.333H52.7205Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M67.5762 124.222H52.7333C50.8369 124.223 49.0171 123.473 47.6731 122.135C46.329 120.797 45.5704 118.981 45.5636 117.084C45.5687 115.192 46.3221 113.379 47.6594 112.041C48.9966 110.702 50.8091 109.947 52.7011 109.94H67.5439C69.4359 109.947 71.2484 110.702 72.5856 112.041C73.9229 113.379 74.6763 115.192 74.6814 117.084C74.6746 118.97 73.9249 120.776 72.5948 122.112C71.2647 123.448 69.4615 124.206 67.5762 124.222Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M132.646 34.416L120.572 85.8626H48.5387L68.6862 0L118.565 11.7L132.646 34.416Z"
            fill="#407BFF"
          />
          <path
            id="Vector_8"
            d="M132.646 34.416L120.572 85.8626H48.5387L68.6862 0L118.565 11.7L132.646 34.416Z"
            fill="#EDF6FF"
          />
          <path
            id="Vector_9"
            d="M123.25 43.98L68.5378 31.1377C68.3508 31.0944 68.1741 31.0146 68.018 30.9029C67.8618 30.7911 67.7293 30.6496 67.628 30.4866C67.5266 30.3235 67.4585 30.142 67.4275 29.9525C67.3965 29.763 67.4032 29.5693 67.4472 29.3824C67.5348 29.0043 67.7688 28.6764 68.0979 28.4708C68.427 28.2651 68.8243 28.1984 69.2025 28.2853L123.921 41.1083C124.298 41.1987 124.625 41.4346 124.829 41.7646C125.033 42.0946 125.099 42.4919 125.012 42.87C124.924 43.2501 124.689 43.5801 124.359 43.788C124.029 43.9959 123.631 44.065 123.25 43.98Z"
            fill="url(#paint3_linear_43_9171)"
          />
          <path
            id="Vector_10"
            d="M121.159 52.8857L66.4469 40.0434C66.2596 39.9994 66.0828 39.9188 65.9266 39.8065C65.7704 39.6941 65.6379 39.552 65.5366 39.3884C65.4353 39.2248 65.3673 39.0429 65.3363 38.853C65.3054 38.663 65.3122 38.4689 65.3563 38.2816C65.4467 37.9042 65.6826 37.5779 66.0126 37.3736C66.3426 37.1693 66.7399 37.1037 67.118 37.191L121.83 50.0333C122.207 50.1223 122.533 50.3569 122.738 50.6859C122.942 51.0148 123.008 51.4113 122.921 51.7886C122.832 52.1672 122.596 52.4951 122.266 52.7007C121.936 52.9063 121.538 52.9728 121.159 52.8857Z"
            fill="url(#paint4_linear_43_9171)"
          />
          <path
            id="Vector_11"
            d="M119.068 61.7591L64.356 48.9233C63.9786 48.8328 63.6523 48.5969 63.448 48.2669C63.2437 47.9369 63.1781 47.5396 63.2654 47.1615C63.3574 46.785 63.5936 46.4597 63.9231 46.2557C64.2526 46.0517 64.6491 45.9853 65.0272 46.0709L119.739 58.9067C119.926 58.9507 120.103 59.0313 120.259 59.1436C120.416 59.256 120.548 59.3981 120.649 59.5617C120.751 59.7253 120.819 59.9072 120.85 60.0971C120.881 60.287 120.874 60.4812 120.83 60.6685C120.786 60.8558 120.705 61.0326 120.593 61.1888C120.48 61.345 120.338 61.4775 120.175 61.5788C120.011 61.68 119.829 61.7481 119.639 61.779C119.449 61.81 119.255 61.8032 119.068 61.7591Z"
            fill="url(#paint5_linear_43_9171)"
          />
          <path
            id="Vector_12"
            d="M116.984 70.6906L62.2651 57.8871C61.8886 57.7951 61.5634 57.5589 61.3594 57.2293C61.1554 56.8998 61.089 56.5033 61.1745 56.1253C61.265 55.7479 61.5009 55.4216 61.8309 55.2173C62.1608 55.013 62.5582 54.9474 62.9363 55.0347L117.648 67.8705C118.026 67.9611 118.353 68.1967 118.558 68.5264C118.763 68.8561 118.831 69.2534 118.745 69.6323C118.649 70.0046 118.411 70.3243 118.081 70.5223C117.752 70.7203 117.357 70.7807 116.984 70.6906Z"
            fill="url(#paint6_linear_43_9171)"
          />
          <path
            id="Vector_13"
            d="M93.1382 74.4917L60.1807 66.7477C59.8071 66.653 59.4859 66.4151 59.2866 66.0853C59.0873 65.7556 59.026 65.3606 59.1159 64.9859C59.1592 64.7988 59.239 64.6222 59.3507 64.466C59.4625 64.3099 59.6039 64.1774 59.767 64.076C59.9301 63.9747 60.1116 63.9066 60.3011 63.8756C60.4906 63.8445 60.6843 63.8512 60.8712 63.8952L93.8352 71.6393C94.0225 71.6834 94.1993 71.7639 94.3555 71.8763C94.5117 71.9886 94.6442 72.1307 94.7455 72.2943C94.8467 72.4579 94.9148 72.6399 94.9458 72.8298C94.9767 73.0197 94.9699 73.2138 94.9258 73.4011C94.882 73.5912 94.8006 73.7706 94.6865 73.9288C94.5724 74.0869 94.4279 74.2208 94.2614 74.3223C94.0949 74.4239 93.9098 74.4912 93.7169 74.5203C93.524 74.5494 93.3273 74.5397 93.1382 74.4917Z"
            fill="url(#paint7_linear_43_9171)"
          />
          <path
            id="Vector_14"
            d="M132.646 34.416L114.247 30.0987L118.565 11.7L132.646 34.416Z"
            fill="url(#paint8_linear_43_9171)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_43_9171"
            x1="43.5981"
            y1="49.3718"
            x2="53.0831"
            y2="50.1534"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_43_9171"
            x1="30.2709"
            y1="56.7066"
            x2="48.2"
            y2="57.4367"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_43_9171"
            x1="4.30328"
            y1="50.9854"
            x2="298.471"
            y2="81.3927"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_43_9171"
            x1="68.8257"
            y1="29.1104"
            x2="162.056"
            y2="50.0376"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_43_9171"
            x1="66.7347"
            y1="38.0157"
            x2="159.966"
            y2="58.9401"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_43_9171"
            x1="64.6451"
            y1="46.8965"
            x2="157.873"
            y2="67.8283"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_43_9171"
            x1="62.5555"
            y1="55.8574"
            x2="155.77"
            y2="76.826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_43_9171"
            x1="59.958"
            y1="64.4405"
            x2="118.441"
            y2="76.5075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_43_9171"
            x1="114.7"
            y1="12.9423"
            x2="145.881"
            y2="14.493"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#004B96" />
            <stop offset="1" stopColor="#0DEAB3" />
          </linearGradient>
        </defs>
      </svg>

      <p className="mt-4 text-center max-[430px]:text-sm max-[630px]:text-md max-[930px]:text-lg text-xl">
        {info}
      </p>
    </div>
  );
}

export default NoData;
