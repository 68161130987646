import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
  invokeFecthApi: false,
};

export const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userInfo = action.payload;
    },
    invokeFecthApiStatus: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.invokeFecthApi = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, invokeFecthApiStatus } = userInfo.actions;

export default userInfo.reducer;
