import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toaster from "./Helper/Toaster.jsx";
import Routers from "./Routers.jsx";
import "bootstrap/dist/css/bootstrap.css";
import logout from "./utils/Logout.js";

const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes

const useInactivityTimeout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        logout(navigate, "You have been logged out due to inactivity.");
      }, INACTIVITY_TIMEOUT);
    };

    const events = ["mousemove", "keydown", "scroll", "click"];

    const handleEvent = () => {
      resetTimeout();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleEvent);
    });

    resetTimeout();

    return () => {
      clearTimeout(timeout);
      events.forEach((event) => {
        window.removeEventListener(event, handleEvent);
      });
    };
  }, [navigate]);
};

function App() {
  useInactivityTimeout();

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE;
  }, []);

  return (
    <>
      <Routers />
      <Toaster />
    </>
  );
}

export default App;
