import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Modal } from "antd";
import { useFormik } from "formik";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";

const ExperienceDetail = ({
  userVerificationDetail,
  taskDetail,
  experienceCheckInfo,
  getExperienceCheckInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [showAddStaff, setShowaddStaff] = useState(false);
  const [showViewForm, setShowViewForm] = useState(false);
  const [details, setDetails] = useState({});

  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);

  // const [remarkForActivity, setRemarkForActivity] = useState("");
  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  const createActivity = async (action, caseId, checkId) => {
    let body = {
      userVerificationRequestId: caseId,
      checkId: checkId,
      FEName: `${localStorageData?.firstName} ${localStorageData?.lastName}`,
      checkName: "Experience",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const experienceType = [{ name: "Fresher" }, { name: "Experience" }];

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Month is zero-based
    const day = ("0" + date.getDate()).slice(-2);

    return `${month}/${day}/${year}`;
  };

  // const createWebForm = async () => {
  //   let body = {
  //     webFormFields: {
  //       candidateName: `${taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
  //         } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
  //       email: values.email,
  //       endDate: formatDate(new Date(values.endDate)),
  //       startDate: formatDate(new Date(values.startDate)),
  //       designation: checked3,
  //       reasonToLeave: checked4,
  //       feedback: checked5,
  //       annualCCT: checked6,
  //     },
  //     webFormDetails: {
  //       candidateName: `${taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
  //         } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
  //       email: values.email,
  //       startDate: null,
  //       endDate: null,
  //       designation: null,
  //       reasonToLeave: null,
  //       feedback: null,
  //       annualCCT: null,
  //     },
  //     webFormURL: process.env.REACT_APP_FRONTEND_URL,
  //     documentId: WebFormReferenceId,
  //     userVerificationRequestId:
  //       location?.state?.taskList?.userVerificationRequestId,
  //   };
  //   await ApiService.patch(`task/status/${taskDetail?._id}`, body)
  //     .then(() => {
  //       toast.success("Task Submitted Successfully");
  //       navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleGetWebForm = (id) => {
    setShowViewForm(true);
    ApiService.get(`webForm/${id}`)
      .then((res) => {
        formik.setFieldValue("name", res?.data?.webFormDetails?.name);
        formik.setFieldValue("email", res?.data?.webFormDetails?.email);
        formik.setFieldValue("startDate", res?.data?.webFormDetails?.startDate);
        formik.setFieldValue("endDate", res?.data?.webFormDetails?.endDate);
        formik.setFieldValue("feedback", res?.data?.webFormDetails?.feedBack);
        formik.setFieldValue(
          "designation",
          res?.data?.webFormDetails?.designation
        );
        formik.setFieldValue(
          "reasonToLeave",
          res?.data?.webFormDetails?.leaveReason
        );
        formik.setFieldValue("annualCCT", res?.data?.webFormDetails?.annualCTC);
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },

    onSubmit: async (values) => {

      setShowaddStaff(true);
      let body = {
        webFormFields: {
          candidateName: `${
            taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
          } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
          email: details.hrEmail,
          endDate: formatDate(new Date(values.endDate)),
          startDate: formatDate(new Date(values.startDate)),
          designation: checked3,
          reasonToLeave: checked4,
          feedback: checked5,
          annualCCT: checked6,
        },
        webFormDetails: {
          candidateName: `${
            taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
          } ${taskDetail?.userVerificationRequest?.userInfo?.lastName || ""}`,
          email: details.hrEmail,
          startDate: null,
          endDate: null,
          designation: null,
          reasonToLeave: null,
          feedback: null,
          annualCCT: null,
        },
        webFormURL: process.env.REACT_APP_FRONTEND_BASE_URL,
        documentId: details?.documentId,
        userVerificationRequestId: details?.userVerificationRequestId,
      };
      ApiService.post("webForm", body)
        .then(async (res) => {
          setShowaddStaff(false);
          toast.success(res.data.message);
          formik.resetForm();
          getExperienceCheckInfo(userVerificationDetail);
          setDetails({});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        // Create a copy of the original data array
        const newData = [...prevData];
        // Update the specific object at the given index
        newData[index] = {
          ...newData[index],
          experience: {
            ...newData[index].experience,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };

  useEffect(() => {
    setData(experienceCheckInfo);
    SetFileUplaod(data?.experience?.reportUrl || []);
  }, [experienceCheckInfo]);

  const updateExperienceDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "experienceCheck/link";
    } else {
      url = "experienceCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      experience: {
        ...data[index]?.experience,
        reportUrl: [
          ...new Set([...data[index]?.experience?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.experience?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.experience?.uploadProofUrl,
            ...uploadProof,
          ]),
        ],
      },
    };
    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      // createActivity(
      //   remarkForActivity,
      //   userVerificationDetail,
      //   experienceCheckInfo[0]._id
      // );
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Experience details updated successfully");
      if (flag === "false") {
        getExperienceCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "file":
        SetFileUplaod([...fileUpload, ...uploadedFileUrls]);
        toast.success("File successfully uploaded");
        break;
      case "uploadProof":
        SetUploadProof([...uploadProof, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const updateCheckStatus = async (status, e, id) => {
    e.preventDefault();
    const body = { status: status };
    try {
      // Create the request body with only the status field

      // Make the API call to update only the status field
      await ApiService.patch(`experienceCheck/experience/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      // setCheckStatus(status);
      getExperienceCheckInfo(userVerificationDetail);
      // navigate("/staff-member-dashboard");
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "experience",
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {
  //       // toast.success("Task Item Updated Successfully");
  //       // navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Experience Task Submitted!",
                userVerificationDetail,
                experienceCheckInfo[0]._id
              );
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            title={item?.qualification}
            className={`border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"}`}
          >
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <div className="flex flex-row justify-between mb-[10px]">
                  <div className="flex gap-2">
                    {item?.experience?.status === "Pending" ? (
                      <div className="flex flex-row gap-1">
                        <div
                          role="presentation"
                          onClick={(e) => {
                            createActivity(
                              "Pending -> Approved",
                              item?.userVerificationRequestId,
                              item?._id
                            );
                            updateCheckStatus(
                              "Approved",
                              e,
                              item?._id,
                              dataIndex
                            );
                          }}
                        >
                          <Button
                            label="Approve"
                            severity="success"
                            icon="pi text-white pi-check-circle"
                            size="small"
                          />
                        </div>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            createActivity(
                              "Pending -> Rejected",
                              item?.userVerificationRequestId,
                              item?._id
                            );
                            updateCheckStatus(
                              "Rejected",
                              e,
                              item?._id,
                              dataIndex
                            );
                          }}
                        >
                          <Button
                            label="Reject"
                            icon="pi text-white pi-times-circle"
                            severity="danger"
                            size="small"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>{getSeverity(item?.experience?.status)}</div>
                      </>
                    )}
                  </div>
                  {process.env.REACT_APP_ENABLE_WEBFORM_FEATURE === "true" && (
                    <>
                      {item?.inefficiencyId !== null ? (
                        <>
                          <div>
                            <button
                              className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                              onClick={() => {
                                handleGetWebForm(item?.inefficiencyId);
                              }}
                            >
                              View Feedback
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <button
                              className="w-[100px]  text-white text-[10px] font-medium h-[40px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                              onClick={() => {
                                setShowaddStaff(true);
                                setDetails({
                                  documentId: item?._id,
                                  hrEmail: item?.experience?.hrEmail,
                                  userVerificationRequestId:
                                    item?.userVerificationRequestId,
                                });
                              }}
                            >
                              Ask Feedback
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              <form>
                <div className="col-md-4">
                  <Dropdown
                    value={item?.experience?.type || ""}
                    onChange={(e) => onInputChange(e, dataIndex)}
                    options={experienceType}
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select Type"
                    filter
                    valueTemplate={selectedCountryTemplate}
                    itemTemplate={countryOptionTemplate}
                    className="w-full md:w-14rem"
                    inputId="type"
                    name="type"
                    disabled={flag === "true"}
                  />
                </div>
                {item?.experience?.type === "Experience" && (
                  <>
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Company Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="companyName"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.experience?.companyName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Designation
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="designation"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.experience?.designation || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Period Of Employment(In Months)
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="periodOfEmployement"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "numeric")
                          }
                          value={item?.experience?.periodOfEmployement || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Location
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="location"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.experience?.location || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Company Address
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="companyAddress"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.experience?.companyAddress || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Contact Detail
                          </label>
                          <InputText
                            className="form-control"
                            keyfilter="int"
                            maxLength={10}
                            name="contactDetail"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.experience?.contactDetail || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                    </div>

                    <div className="row my-2">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Employment Code
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="employmentCode"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.experience?.employmentCode || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Salary
                          </label>
                          <InputText
                            className="form-control"
                            keyfilter="int"
                            name="salary"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.experience?.salary || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Supervisor Name
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="supervisorName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.experience?.supervisorName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                    </div>

                    <div className="row my-2">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="clientType">
                            Supervisor Number
                          </label>
                          <InputText
                            className="form-control"
                            keyfilter="int"
                            name="supervisorNumber"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "numeric")
                            }
                            value={item?.experience?.supervisorNumber || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Supervisor Email
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="supervisorEmail"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "alphanumeric")
                            }
                            value={item?.experience?.supervisorEmail || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            HR Name
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="hrName"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.experience?.hrName || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          HR Number
                        </label>
                        <InputText
                          className="form-control"
                          keyfilter="int"
                          name="hrNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "numeric")
                          }
                          maxLength={10}
                          value={item?.experience?.hrNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          HR Email
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="hrEmail"
                          onChange={(e) =>
                            onInputChange(
                              e,
                              dataIndex,
                              "alphanumericWithSymbols"
                            )
                          }
                          value={item?.experience?.hrEmail || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          UAN Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="uanNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.experience?.uanNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>

                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !==
                        "true" && (
                        <div className="col-md-4">
                          <label className="small mb-1" htmlFor="entityName">
                            Reason For Leaving
                          </label>
                          <InputText
                            className="form-control"
                            type="text"
                            name="reasonForLeaving"
                            onChange={(e) =>
                              onInputChange(e, dataIndex, "text")
                            }
                            value={item?.experience?.reasonForLeaving || ""}
                            disabled={flag === "true"}
                          />
                        </div>
                      )}
                      <div className="col-md-12 border-bottom">
                        <label className="small mb-1" htmlFor="clientType">
                          File Upload{" "}
                          <span className="opacity-70">
                            (Please Choose JPEG,PNG,PDF less than 5MB)
                          </span>
                        </label>
                        {item?.experience?.reportUrl?.length === 0 ||
                        editable === true ? (
                            <FileUpload
                              name="fileUpload[]"
                              multiple
                              auto
                              accept="image/*,application/pdf"
                              customUpload={true}
                              // maxFileSize={50000000}
                              uploadHandler={(e) => handleFileUpload(e, "file")}
                              itemTemplate={customItemTemplate}
                              disabled={flag === "true"}
                            />
                          ) : (
                            ""
                          )}

                        <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                          {(item?.experience?.reportUrl || []).map(
                            (fileUrl, reportIndex) => {
                              // Extract file name from the URL
                              const fileName = fileUrl.split("/").pop();
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();

                              const isImage = ["jpeg", "png"].includes(
                                fileExtension
                              );

                              const isPdf = fileExtension === "pdf";
                              return (
                                <li
                                  key={reportIndex}
                                  className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                                >
                                  <Button
                                    label="Download"
                                    onClick={(e) =>
                                      getImg(
                                        e,
                                        fileName,
                                        dataIndex,
                                        reportIndex,
                                        "img"
                                      )
                                    }
                                    className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                  />

                                  {fileMap[dataIndex]?.[reportIndex] && (
                                    <div className="text-center mt-2">
                                      {isImage && (
                                        <a
                                          href={fileMap[dataIndex][reportIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          download={`file_${dataIndex}_${reportIndex}`}
                                        >
                                          View Image
                                        </a>
                                      )}
                                      {isPdf && (
                                        <a
                                          href={fileMap[dataIndex][reportIndex]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-center"
                                        >
                                          View PDF
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-8 mt-2">
                  <label className="small mb-1" htmlFor="remark">
                    Remark
                  </label>
                  <textarea
                    className="form-control h-[110px]"
                    name="remark"
                    onChange={(e) => {
                      // setRemarkForActivity(e.target.value);
                      onInputChange(e, dataIndex, "alphanumeric");
                    }}
                    value={item?.experience?.remark || ""}
                    disabled={flag === "false"}
                  />
                </div>

                <div className="col-md-12 my-2">
                  <label className="small mb-1" htmlFor="clientType">
                    Upload Proof{" "}
                    <span className="opacity-70">
                      (Please Choose PDF less than 10MB)
                    </span>
                  </label>
                  {item?.experience?.uploadProofUrl?.length === 0 && (
                    <FileUpload
                      name="fileUpload[]"
                      auto
                      multiple
                      accept="image/*"
                      customUpload={true}
                      maxFileSize={10000000}
                      uploadHandler={(e) => handleFileUpload(e, "uploadProof")}
                      itemTemplate={customItemTemplate}
                      disabled={flag === "false"}
                    />
                  )}
                  <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                    {(item?.experience?.uploadProofUrl || []).map(
                      (fileUrl, proofIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        return (
                          <li
                            key={proofIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(
                                  e,
                                  fileName,
                                  dataIndex,
                                  proofIndex,
                                  "proof"
                                )
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />
                            <div className="text-center mt-2">
                              {isImage && proofImage[dataIndex]?.[proofIndex] && (
                                <a
                                  href={proofImage[dataIndex][proofIndex]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download={`file_${dataIndex}_${proofIndex}`}
                                >
                                  View Image
                                </a>
                              )}
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div></div>

                <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                  {flag === "false" &&
                  item?.experience?.status === "Pending" ? (
                      item?.experience?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  {item?.experience?.status === "Pending" ? (
                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateExperienceDetails(e, item._id, dataIndex);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.experience?.status)}</div>
                    </div>
                  ) : (
                    <div>{getSeverity(item?.experience?.status)}</div>
                  )}
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}

      <Modal
        width="50%"
        title="Create WebForm"
        open={showAddStaff}
        footer={null}
        onCancel={() => setShowaddStaff(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <p className="mt-2"> Let’s add your WebForm</p>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Candidate Name"
                id="name"
                value={`${
                  taskDetail?.userVerificationRequest?.userInfo?.firstName || ""
                } ${
                  taskDetail?.userVerificationRequest?.userInfo?.lastName || ""
                }`}
                disabled
              />
              {/* {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">{formik.errors.name}</div>
              ) : null} */}
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Employer Email ID"
                id="email"
                value={`${details?.hrEmail}`}
                disabled
              />
              {/* {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">{formik.errors.email}</div>
              ) : null} */}
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="small mb-1" htmlFor="entityName">
                Start Date
              </label>
              <InputText
                className="form-control"
                type="date"
                name="startDate"
                placeholder="Start Date"
                // onChange={formik.handleChange}
              />
              {/* <Calendar
                className="w-full"
                id="inputStartdate"
                name="startDate"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showIcon
                showOnFocus
                dateFormat="mm-dd-yy"
              /> */}
            </div>
            <div className="col-md-6">
              <label className="small mb-1" htmlFor="entityName">
                End Date
              </label>
              <InputText
                className="form-control"
                type="date"
                name="endDate"
                placeholder="End Date"
                // onChange={formik.handleChange}
              />
              {/* <Calendar
                className="w-full"
                id="inputEnddate"
                name="end Date"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showIcon
                showOnFocus
                dateFormat="mm-dd-yy"
              /> */}
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Ask For Designation</p>
                <InputSwitch
                  checked={checked3}
                  onChange={(e) => setChecked3(e.value)}
                />
              </div>{" "}
            </div>
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Reason to leave</p>
                <InputSwitch
                  checked={checked4}
                  onChange={(e) => setChecked4(e.value)}
                />
              </div>{" "}
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Ask For Feedback</p>
                <InputSwitch
                  checked={checked5}
                  onChange={(e) => setChecked5(e.value)}
                />
              </div>{" "}
            </div>
            <div className="col-md-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="p-inputtext p-component form-control w-[50px] form-control h-[50px] input flex justify-between"
              >
                <p className="text-[#CCCCCC]">Ask For Annual CTC</p>
                <InputSwitch
                  checked={checked6}
                  onChange={(e) => setChecked6(e.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-[20px] text-end">
            <button
              type="submit"
              // disabled={isLoading}
              className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
            >
              Create
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        width="50%"
        title="Feedback Form Submitted By HR"
        open={showViewForm}
        footer={null}
        onCancel={() => setShowViewForm(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputEmployeName">
                Candidate Name
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Candidate Name"
                id="name"
                {...formik.getFieldProps("name")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputEmployeId">
                Employer Email ID
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Employer Email ID"
                id="email"
                {...formik.getFieldProps("email")}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputStartDate">
                Select your Start Date
              </label>
              <InputText
                className="form-control w-[50px]"
                type="date"
                placeholder="StartDate"
                id="startDate"
                {...formik.getFieldProps("startDate")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputEndDate">
                Select your End Date
              </label>
              <InputText
                className="form-control w-[50px]"
                type="date"
                placeholder="EndDate"
                id="endDate"
                {...formik.getFieldProps("endDate")}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputDesigntion">
                Designation
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="Designation"
                id="designation"
                {...formik.getFieldProps("designation")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputReasonToLeave">
                Reason to leave
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="reasonToLeave"
                id="reasonToLeave"
                {...formik.getFieldProps("reasonToLeave")}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-3">
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputFeedback">
                Feedback
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="feedback"
                id="feedback"
                {...formik.getFieldProps("feedback")}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label className="mb-1" htmlFor="inputAnnualCTC">
                Annual CTC
              </label>
              <InputText
                className="form-control w-[50px]"
                type="text"
                placeholder="annualCTC"
                id="annualCCT"
                {...formik.getFieldProps("annualCCT")}
                disabled
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ExperienceDetail;
