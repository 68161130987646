import React, { useState, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { getBase64 } from "../../Helper/Base64File";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";

const IdentityDetails = ({
  userVerificationDetail,
  taskDetail,
  identityCheckInfo,
  getIndetityInfo,
  flag,
}) => {
  const navigate = useNavigate();
  const [aadharPic, setAadharPic] = useState([]);
  const [panPic, setPanPic] = useState([]);
  const [dlPic, setDlPic] = useState([]);
  const [passPortPic, setPassPortPic] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editablePassport, setEditablePassport] = useState(false);
  const [editableAdhar, setEditableAdhar] = useState(false);
  const [editablePan, setEditablePan] = useState(false);
  const [uploadProofAdhar, SetUploadProofAdhar] = useState([]);
  const [uploadProofPan, SetUploadProofPan] = useState([]);
  const [uploadProofDL, SetUploadProofDL] = useState([]);
  const [uploadProofPP, SetUploadProofPP] = useState([]);
  const [adhaarFileMap, setAdhaarFileMap] = useState([]);
  const [panFileMap, setPanFileMap] = useState([]);
  const [dlFileMap, setDlFileMap] = useState([]);
  const [passportFileMap, setPassportFileMap] = useState([]);
  const [aadharProof, setAadharProof] = useState([]);
  const [panProof, setPanProof] = useState([]);
  const [dlProof, setDlProof] = useState([]);
  const [passportProof, setPassportProof] = useState([]);
  const [timer, setTimer] = useState(null);

  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  // let localStorageData = JSON.parse(localStorage.getItem("user"));

  // const createActivity = async (action, caseId, checkId) => {
  //   let body = {
  //     userVerificationRequestId: caseId,
  //     checkId: checkId,
  //     FEName: `${localStorageData?.firstName} ${localStorageData?.lastName}`,
  //     checkName: "Identity",
  //     description: action,
  //     activityDetails: {
  //       action: action,
  //     },
  //   };
  //   await ApiService.post("caseActivity", body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Create Activity");
  //     });
  // };

  useEffect(() => {
    getDiscreteCallInfo(userVerificationDetail);
  }, [userVerificationDetail]);

  const getDiscreteCallInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then((res) => {
        const data = res?.data?.docs[0];
        if (data) {
          setAadharPic(data?.adhaarCard?.upload);
          setPanPic(data?.panCard?.upload || []);
          setDlPic(data?.drivingLicence?.upload || []);
          setPassPortPic(data?.passport?.upload || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTaskStatus = async () => {
    let body = {
      status: "Completed",
    };
    await ApiService.patch(`task/status/${taskDetail?._id}`, body)
      .then(() => {
        toast.success("Task Submitted Successfully");
        navigate("/staff-member-dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  useEffect(() => {
    setData(identityCheckInfo);
  }, [identityCheckInfo]);

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          adhaarCard: {
            ...newData[index].adhaarCard,
            [e.target.name]: e.target.value,
          },
          panCard: {
            ...newData[index].panCard,
            [e.target.name]: e.target.value,
          },
          drivingLicence: {
            ...newData[index].drivingLicence,
            [e.target.name]: e.target.value,
          },
          passport: {
            ...newData[index].passport,
            [e.target.name]: e.target.value,
          },
        };
        return newData;
      });
    }
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });

      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
      case "aadhar":
        setAadharPic([...aadharPic, ...uploadedFileUrls]);
        toast.success("Aadhar successfully uploaded");
        break;
      case "pan":
        setPanPic([...panPic, ...uploadedFileUrls]);
        toast.success("Pan successfully uploaded");
        break;
      case "dl":
        setDlPic([...dlPic, ...uploadedFileUrls]);
        toast.success("DL successfully uploaded");
        break;
      case "passport":
        setPassPortPic([...passPortPic, ...uploadedFileUrls]);
        toast.success("Passport successfully uploaded");
        break;
      case "uploadProofAdhar":
        SetUploadProofAdhar([...uploadProofAdhar, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;

      case "uploadProofPan":
        SetUploadProofPan([...uploadProofPan, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;

      case "uploadProofDL":
        SetUploadProofDL([...uploadProofDL, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;

      case "uploadProofPP":
        SetUploadProofPP([...uploadProofPP, ...uploadedFileUrls]);
        toast.success("Upload proof successfully uploaded");
        break;
      default:
        break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
    }
  };

  const clearImages = () => {
    setAdhaarFileMap([]);
    setPanFileMap([]);
    setDlFileMap([]);
    setPassportFileMap([]);
    setAadharProof([]);
    setPanProof([]);
    setDlProof([]);
    setPassportProof([]);
    toast.warning("Link expired, please download again.");
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "adhaar") {
          setAdhaarFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "pan") {
          setPanFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "dl") {
          setDlFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "passport") {
          setPassportFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "AdhaarProof") {
          setAadharProof((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "PanProof") {
          setPanProof((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "DLProof") {
          setDlProof((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        } else if (imgFlag === "PassportProof") {
          setPassportProof((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const updateDiscreteCalls = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "identityCheck/link";
    } else {
      url = "identityCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      adhaarCard: {
        ...data[index]?.adhaarCard,
        upload: aadharPic,
        remark: data[index]?.adhaarCard?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.adhaarCard?.uploadProofUrl,
            ...uploadProofAdhar,
          ]),
        ],
      },
      panCard: {
        ...data[index]?.panCard,
        upload: panPic,
        remark: data[index]?.panCard?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.panCard?.uploadProofUrl,
            ...uploadProofPan,
          ]),
        ],
      },
      drivingLicence: {
        ...data[index]?.drivingLicence,
        upload: dlPic,
        remark: data[index]?.drivingLicence?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.drivingLicence?.uploadProofUrl,
            ...uploadProofDL,
          ]),
        ],
      },
      passport: {
        ...data[index]?.passport,
        upload: passPortPic || [],
        remark: data[index]?.passport?.remark,
        uploadProofUrl: [
          ...new Set([
            ...data[index]?.passport?.uploadProofUrl,
            ...uploadProofPP,
          ]),
        ],
      },
    };

    try {
      await ApiService.patch(`${url}/${id}`, body);
      setLoading(false);
      getDiscreteCallInfo(userVerificationDetail);
      toast.success("Identity form successfully submitted");
      if (flag === "false") {
        getIndetityInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const handleEdit = () => {
    setEditable(!editable);
  };
  const handleEditPassport = () => {
    setEditablePassport(!editablePassport);
  };
  const handleEditAdhar = () => {
    setEditableAdhar(!editableAdhar);
  };
  const handleEditPan = () => {
    setEditablePan(!editablePan);
  };

  const updateCheckStatus = async (status, e, id, index, type) => {
    e.preventDefault();
    const body = {
      status: status,
      type: type,
    };
    try {
      await ApiService.patch(`identityCheck/identity/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status, type);
      getIndetityInfo(userVerificationDetail);
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };

  // const updateTaskItem = async (id, status, type) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: type,
  //   };
  //   await ApiService.patch(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              updateTaskStatus();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}
      {data?.map((item, dataIndex) => (
        <>
          <Card
            // key={Math.random().toString(36).substr(2, 9)}
            title={item?.qualification}
            className={`border-2 ${dataIndex === 0 ? "mt-0" : "mt-4"}`}
          >
            <div>
              <form>
                <div className="border-2 p-3">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.adhaarCard?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "adhaarCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "adhaarCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Adhaar Card</h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="adharName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.adhaarCard?.adharName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Adhaar Number
                        </label>
                        <InputText
                          className="form-control"
                          keyfilter="int"
                          name="adhaarNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "numeric")
                          }
                          value={item?.adhaarCard?.adhaarNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {aadharPic?.length === 0 || editableAdhar === true ? (
                      <FileUpload
                        name="adhaar"
                        auto
                        multiple
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "aadhar")}
                        itemTemplate={customItemTemplate}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}

                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {aadharPic?.map((fileUrl, reportIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        const isPdf = fileExtension === "pdf";
                        return (
                          <li
                            key={reportIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(
                                  e,
                                  fileName,
                                  dataIndex,
                                  reportIndex,
                                  "adhaar"
                                )
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />

                            {adhaarFileMap[dataIndex]?.[reportIndex] && (
                              <div className="text-center mt-2">
                                {isImage && (
                                  <a
                                    href={adhaarFileMap[dataIndex][reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${reportIndex}`}
                                  >
                                    View Image
                                  </a>
                                )}
                                {isPdf && (
                                  <a
                                    href={adhaarFileMap[dataIndex][reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                  >
                                    View PDF
                                  </a>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="remarkOfAdhaar">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remarkOfAdhaar"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.adhaarCard?.remarkOfAdhaar || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose PDF less than 10MB)
                      </span>
                    </label>
                    {item?.adhaarCard?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofAdhar")
                        }
                        itemTemplate={customItemTemplate}
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.adhaarCard?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "AdhaarProof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  aadharProof[dataIndex]?.[proofIndex] && (
                                  <a
                                    href={aadharProof[dataIndex][proofIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${proofIndex}`}
                                  >
                                      View Image
                                  </a>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      aadharPic?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEditAdhar(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.adhaarCard?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.adhaarCard?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.adhaarCard?.status)}</div>
                    )}
                  </div>
                </div>
                <div className="border-2 p-3 mt-2">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.panCard?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "panCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "panCard"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Pan Card</h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="panName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.panCard?.panName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Pan Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="panNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.panCard?.panNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {panPic?.length === 0 || editablePan === true ? (
                      <FileUpload
                        name="Pan"
                        auto
                        multiple
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "pan")}
                        itemTemplate={customItemTemplate}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {panPic?.map((fileUrl, reportIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        const isPdf = fileExtension === "pdf";
                        return (
                          <li
                            key={reportIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(
                                  e,
                                  fileName,
                                  dataIndex,
                                  reportIndex,
                                  "pan"
                                )
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />

                            {panFileMap[dataIndex]?.[reportIndex] && (
                              <div className="text-center mt-2">
                                {isImage && (
                                  <a
                                    href={panFileMap[dataIndex][reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${reportIndex}`}
                                  >
                                    View Image
                                  </a>
                                )}
                                {isPdf && (
                                  <a
                                    href={panFileMap[dataIndex][reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                  >
                                    View PDF
                                  </a>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="remarkOfPan">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remarkOfPan"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.panCard?.remarkOfPan || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose PDF less than 10MB)
                      </span>
                    </label>
                    {item?.panCard?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofPan")
                        }
                        itemTemplate={customItemTemplate}
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.panCard?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "PanProof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  panProof[dataIndex]?.[proofIndex] && (
                                  <a
                                    href={panProof[dataIndex][proofIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${proofIndex}`}
                                  >
                                      View Image
                                  </a>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      panPic?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEditPan(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.panCard?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.panCard?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.panCard?.status)}</div>
                    )}
                  </div>
                </div>
                <div className="border-2 p-3 mt-2">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.drivingLicence?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "drivingLicence"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "drivingLicence"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Driving Licence </h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="drivingName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.drivingLicence?.drivingName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Driving Licence Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="drivingLicenceNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={
                            item?.drivingLicence?.drivingLicenceNumber || ""
                          }
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {dlPic?.length === 0 || editable === true ? (
                      <FileUpload
                        name="dl"
                        auto
                        multiple
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "dl")}
                        itemTemplate={customItemTemplate}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {dlPic?.map((fileUrl, reportIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        const isPdf = fileExtension === "pdf";
                        return (
                          <li
                            key={reportIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(
                                  e,
                                  fileName,
                                  dataIndex,
                                  reportIndex,
                                  "dl"
                                )
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />

                            {dlFileMap[dataIndex]?.[reportIndex] && (
                              <div className="text-center mt-2">
                                {isImage && (
                                  <a
                                    href={dlFileMap[dataIndex][reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${reportIndex}`}
                                  >
                                    View Image
                                  </a>
                                )}
                                {isPdf && (
                                  <a
                                    href={dlFileMap[dataIndex][reportIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                  >
                                    View PDF
                                  </a>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="remarkOfDL">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remarkOfDL"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.drivingLicence?.remarkOfDL || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose PDF less than 10MB)
                      </span>
                    </label>
                    {item?.drivingLicence?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofDL")
                        }
                        itemTemplate={customItemTemplate}
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.drivingLicence?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "DLProof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  dlProof[dataIndex]?.[proofIndex] && (
                                  <a
                                    href={dlProof[dataIndex][proofIndex]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${proofIndex}`}
                                  >
                                      View Image
                                  </a>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      dlPic?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.drivingLicence?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.drivingLicence?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.drivingLicence?.status)}</div>
                    )}
                  </div>
                </div>
                <div className="border-2 p-3 mt-2">
                  <div className="mb-[10px]">
                    {flag === "true" && (
                      <>
                        <div className="flex justify-between">
                          {item?.passport?.status === "Pending" ? (
                            <div className="flex flex-row gap-1">
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Approved",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "passport"
                                  );
                                }}
                              >
                                <Button
                                  label="Approve"
                                  severity="success"
                                  icon="pi text-white pi-check-circle"
                                  size="small"
                                />
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  updateCheckStatus(
                                    "Rejected",
                                    e,
                                    item?._id,
                                    dataIndex,
                                    "passport"
                                  );
                                }}
                              >
                                <Button
                                  label="Reject"
                                  icon="pi text-white pi-times-circle"
                                  severity="danger"
                                  size="small"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <h4>Passport </h4>
                  {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS !== "true" && (
                    <div className="row my-2">
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="clientType">
                          Name
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="passportName"
                          onChange={(e) => onInputChange(e, dataIndex, "text")}
                          value={item?.passport?.passportName || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="small mb-1" htmlFor="entityName">
                          Passport Number
                        </label>
                        <InputText
                          className="form-control"
                          type="text"
                          name="passportNumber"
                          onChange={(e) =>
                            onInputChange(e, dataIndex, "alphanumeric")
                          }
                          value={item?.passport?.passportNumber || ""}
                          disabled={flag === "true"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 border-bottom">
                    {passPortPic?.length === 0 || editablePassport === true ? (
                      <FileUpload
                        name="passport"
                        auto
                        multiple
                        accept="image/*,application/pdf"
                        customUpload={true}
                        // maxFileSize={50000000}
                        uploadHandler={(e) => handleFileUpload(e, "passport")}
                        itemTemplate={customItemTemplate}
                        disabled={flag === "true"}
                      />
                    ) : (
                      ""
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex lg:flex-row flex-col lg:overflow-x-auto overflow-style-none md:rounded-lg">
                      {passPortPic?.map((fileUrl, reportIndex) => {
                        // Extract file name from the URL
                        const fileName = fileUrl.split("/").pop();
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const isImage = ["jpeg", "png"].includes(fileExtension);

                        const isPdf = fileExtension === "pdf";
                        return (
                          <li
                            key={reportIndex}
                            className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                          >
                            <Button
                              label="Download"
                              onClick={(e) =>
                                getImg(
                                  e,
                                  fileName,
                                  dataIndex,
                                  reportIndex,
                                  "passport"
                                )
                              }
                              className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                            />

                            {passportFileMap[dataIndex]?.[reportIndex] && (
                              <div className="text-center mt-2">
                                {isImage && (
                                  <a
                                    href={
                                      passportFileMap[dataIndex][reportIndex]
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${reportIndex}`}
                                  >
                                    View Image
                                  </a>
                                )}
                                {isPdf && (
                                  <a
                                    href={
                                      passportFileMap[dataIndex][reportIndex]
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                  >
                                    View PDF
                                  </a>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-md-8 mt-2">
                    <label className="small mb-1" htmlFor="remarkOfPassport">
                      Remark
                    </label>
                    <textarea
                      className="form-control h-[110px]"
                      name="remarkOfPassport"
                      onChange={(e) =>
                        onInputChange(e, dataIndex, "alphanumeric")
                      }
                      value={item?.passport?.remarkOfPassport || ""}
                      disabled={flag === "false"}
                    />
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="small mb-1" htmlFor="clientType">
                      Upload Proof{" "}
                      <span className="opacity-70">
                        (Please Choose PDF less than 10MB)
                      </span>
                    </label>
                    {item?.passport?.uploadProofUrl?.length === 0 && (
                      <FileUpload
                        name="fileUpload[]"
                        auto
                        multiple
                        accept="image/*"
                        customUpload={true}
                        maxFileSize={10000000}
                        uploadHandler={(e) =>
                          handleFileUpload(e, "uploadProofPP")
                        }
                        itemTemplate={customItemTemplate}
                        disabled={flag === "false"}
                      />
                    )}
                    <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                      {(item?.passport?.uploadProofUrl || []).map(
                        (fileUrl, proofIndex) => {
                          // Extract file name from the URL
                          const fileName = fileUrl.split("/").pop();
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();

                          const isImage = ["jpeg", "png"].includes(
                            fileExtension
                          );

                          return (
                            <li
                              key={proofIndex}
                              className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                            >
                              <Button
                                label="Download"
                                onClick={(e) =>
                                  getImg(
                                    e,
                                    fileName,
                                    dataIndex,
                                    proofIndex,
                                    "PassportProof"
                                  )
                                }
                                className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                              />
                              <div className="text-center mt-2">
                                {isImage &&
                                  passportProof[dataIndex]?.[proofIndex] && (
                                  <a
                                    href={
                                      passportProof[dataIndex][proofIndex]
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`file_${dataIndex}_${proofIndex}`}
                                  >
                                      View Image
                                  </a>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  <div></div>
                  <div className="flex justify-end gap-2 mt-[10px] max-sm:flex max-sm:flex-col">
                    {flag === "false" ? (
                      passPortPic?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEditPassport(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {item?.passport?.status === "Pending" ? (
                      <div className="flex gap-2">
                        <Button
                          label="Save"
                          type="submit"
                          loading={loading}
                          onClick={(e) => {
                            updateDiscreteCalls(e, item._id, dataIndex);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                        <div>{getSeverity(item?.passport?.status)}</div>
                      </div>
                    ) : (
                      <div>{getSeverity(item?.passport?.status)}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Card>
          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
            />
          </div>
        </>
      ))}
    </>
  );
};

export default IdentityDetails;
